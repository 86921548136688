import moment from 'moment';

export default class DateHelper {
  static get dayNames() {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  }

  static get defaultDateTimeFormatMoment() {
    return 'DD-MM-YYYY HH:mm';
  }

  // @see https://primefaces.org/primevue/calendar
  static get defaultDateFormatPrimeVue() {
    return 'dd-mm-yy';
  }

  // @see https://primefaces.org/primevue/calendar
  static get defaultTimeFormatPrimeVue() {
    return '24';
  }

  static formatDate(date: string | Date, includeTime = true, local = true) {
    return (local ? moment.utc(date).local() : moment.utc(date)).format(`DD-MM-YYYY${includeTime ? ' HH:mm' : ''}`);
  }

  static formatDateTextual(date: string) {
    if (moment(date) > moment().add(moment.duration({ hours: 12 })) || moment(date) < moment().subtract(moment.duration({ hours: 12 }))) {
      return moment(date).format(DateHelper.defaultDateTimeFormatMoment);
    }
    return moment(date).fromNow();
  }
}
