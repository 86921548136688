<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <span class="item mr-1 whitespace-nowrap overflow-ellipsis overflow-hidden">
    <FaIcon v-if="faIcon" class="p-item-icon mr-1" :icon="faIcon" />
    <span class="whitespace-nowrap overflow-ellipsis overflow-hidden">
      <slot v-if="$slots.value" name="value" :label="label" />
      <template v-else>
        {{ label }}
      </template>
    </span>
  </span>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

@Component({
  components: { FaIcon },
})
class Item extends ComponentBase {
  @Prop
  declare public label: string;

  @Prop
  declare public faIcon?: string;
}

export default toNative(Item);
</script>
