<template>
  <div class="toolbar flex-grow-0 h-14 fadein flex justify-between items-center justify-center px-1 bg-surface-a surface-section border-b border-surface">
    <div class="start" v-if="$slots.start">
      <slot name="start" />
    </div>
    <div class="content overflow-auto w-full flex-grow">
      <slot />
    </div>
    <div class="end" v-if="$slots.end">
      <slot name="end" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.toolbar {
    max-height: 3rem;
    min-height: 3.5rem;
}
</style>
