import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlueprintEditItemField = _resolveComponent("BlueprintEditItemField")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.mode === 'new' ? _ctx.newFields : _ctx.editFields), (field) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: field.field
      }, [
        (_ctx.$slots[`edit-${field.field}`])
          ? _renderSlot(_ctx.$slots, `edit-${field.field}`, {
              key: 0,
              model: _ctx.modelValue,
              field: field
            })
          : (_openBlock(), _createBlock(_component_BlueprintEditItemField, {
              key: 1,
              invalid: _ctx.invalidFields?.includes(field.field),
              modelValue: _ctx.modelValue[field.field],
              "onUpdate:modelValue": [
                ($event: any) => ((_ctx.modelValue[field.field]) = $event),
                _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.modelValue)))
              ],
              field: field
            }, null, 8, ["invalid", "modelValue", "onUpdate:modelValue", "field"]))
      ], 64))
    }), 128))
  ]))
}