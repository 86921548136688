import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "start flex items-center mx-1"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "content overflow-hidden overflow-ellipsis flex items-center flex-grow" }
const _hoisted_4 = {
  key: 0,
  class: "end items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageSideMenuToggle = _resolveComponent("PageSideMenuToggle")!
  const _component_PageToolbar = _resolveComponent("PageToolbar")!

  return (_openBlock(), _createBlock(_component_PageToolbar, null, {
    start: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.$slots.start && _ctx.sideMenuAvailable)
              ? (_openBlock(), _createBlock(_component_PageSideMenuToggle, {
                  key: 0,
                  class: "md:hidden"
                }))
              : _createCommentVNode("", true),
            (_ctx.$slots.start)
              ? _renderSlot(_ctx.$slots, "start", { key: 1 })
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    end: _withCtx(() => [
      (!_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.$slots.end)
              ? _renderSlot(_ctx.$slots, "end", { key: 0 })
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (!_ctx.loading )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "default")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}