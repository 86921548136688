import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["summary-card-col", {
      'col-auto': !_ctx.col, [`col-span-${_ctx.col}`]: _ctx.col, [`sm:col-span-${_ctx.sm}`]: _ctx.sm, [`md:col-span-${_ctx.md}`]: _ctx.md, [`lg:col-span-${_ctx.lg}`]: _ctx.lg, [`xl:col-span-${_ctx.xl}`]: _ctx.xl,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}