import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "max-h-screen min-h-screen max-w-screen min-w-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_DynamicDialog = _resolveComponent("DynamicDialog")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LarApp = _resolveComponent("LarApp")!
  const _component_PushNotificationsGuard = _resolveComponent("PushNotificationsGuard")!
  const _component_AuthGuard = _resolveComponent("AuthGuard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ConfirmDialog),
    _createVNode(_component_Toast, { position: "top-center" }),
    _createVNode(_component_DynamicDialog),
    _createVNode(_component_AuthGuard, { ref: "authGuard" }, {
      default: _withCtx(() => [
        _createVNode(_component_PushNotificationsGuard, null, {
          default: _withCtx(() => [
            _createVNode(_component_LarApp, null, {
              default: _withCtx(() => [
                _createVNode(_component_router_view)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512)
  ]))
}