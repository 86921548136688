import { RouteLocationNormalizedLoaded, Router } from 'vue-router';

import { BlueprintOptions, DefaultEntityType } from '@/components/helpers/blueprint';

export interface SortHelperOpts<TEntity extends DefaultEntityType = DefaultEntityType> {
  id: string,
  $route: RouteLocationNormalizedLoaded,
  opts: BlueprintOptions<TEntity>,
  $router: Router,
  defaultSortBy: string,
  defaultSortDesc: boolean,
}

export class SortHelper<TEntity extends DefaultEntityType = DefaultEntityType> {
  public id: string;

  private opts: BlueprintOptions<TEntity>;

  private $router: Router;

  private $route: RouteLocationNormalizedLoaded;

  private defaultSortBy: string;

  private defaultSortDesc: boolean;

  private sortByInternal: string | undefined;

  private sortByDescInternal: boolean | undefined;

  private sortByApplied: string | undefined;

  private sortByDescApplied: boolean | undefined;

  constructor(options: SortHelperOpts<TEntity>) {
    this.id = options.id;
    this.$route = options.$route;
    this.$router = options.$router;
    this.opts = options.opts;
    this.defaultSortBy = options.defaultSortBy;
    this.defaultSortDesc = options.defaultSortDesc;
    this.setFromQuery();
  }

  public get sortableFields() {
    return (this.opts.fields?.filter((f) => !!f.sortable) ?? []);
  }

  public get sortBy() {
    return this.sortByInternal ?? this.defaultSortBy;
  }

  public set sortBy(v: string | undefined) {
    this.sortByInternal = v;
  }

  public get sortByDesc() {
    return this.sortByDescInternal ?? this.defaultSortDesc;
  }

  public set sortByDesc(v: boolean | undefined) {
    this.sortByDescInternal = v;
  }

  private setFromQuery() {
    const params = this.$route.query;
    this.sortBy = String(params[`${this.id}_sortBy`] ?? this.defaultSortBy);
    this.sortByDesc = params[`${this.id}_sortByDesc`] === 'true' ? true : this.defaultSortDesc;
    this.sortByDesc = params[`${this.id}_sortByDesc`] === 'false' ? false : this.defaultSortDesc;
  }

  public apply() {
    this.sortByApplied = this.sortBy;
    this.sortByDescApplied = this.sortByDesc;
    this.updateRoute();
  }

  public cancel() {
    this.sortBy = this.sortByApplied;
    this.sortByDesc = this.sortByDescApplied;
  }

  public reset() {
    this.sortBy = this.defaultSortBy;
    this.sortByDesc = this.defaultSortDesc;
    this.updateRoute();
  }

  private async updateRoute() {
    const query: Record<string, string|undefined> = {};
    query[`${this.id}_sortBy`] = this.sortByApplied || undefined;
    query[`${this.id}_sortByDesc`] = this.sortByDescApplied ? 'true' : 'false';
    await this.$router.replace({
      ...this.$route,
      query: { ...this.$route.query, ...query },
    });
  }
}
