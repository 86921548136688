import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputIcon = _resolveComponent("InputIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_IconField = _resolveComponent("IconField")!

  return (_openBlock(), _createBlock(_component_IconField, { class: "w-full" }, {
    default: _withCtx(() => [
      _createVNode(_component_InputIcon, { class: "pi pi-search" }),
      _createVNode(_component_InputText, {
        class: "w-full",
        modelValue: _ctx.qInternal,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.qInternal) = $event)),
        onInput: _ctx.emitValue,
        placeholder: _ctx.placeholder ?? _ctx.$t('Search')
      }, null, 8, ["modelValue", "onInput", "placeholder"])
    ]),
    _: 1
  }))
}