<template>
  <div v-if="filterHelper.filterableFields.length || filterHelper.searchableFields.length" class="whitespace-nowrap">
    <Button class="!m-0 !p-1" :class="{ 'font-bold': !!count }" text @click="($event: MouseEvent) => filterDialog = true">
      <FaIcon class="text-lg sm:text-base" icon="sliders" />
      <span class="hidden sm:inline-block" :key="count">
        <span>{{ $t('Filters') }}</span>
        {{ count ? `(${count})` : '' }}
      </span>
    </Button>
    <Dialog v-model:visible="filterDialog" :closeOnEscape="false" :showHeader="false" :modal="true">
      <div class="flex flex-col flex-auto">
        <div class="flex sticky top-0 py-4 z-10 bg-surface-0 justify-end mt-1">
          <Button size="small" :disabled="!filterHelper.count" @click="reset" text icon="pi pi-refresh" :label="$t('Reset all')" />
        </div>
        <div v-if="filterHelper.searchableFields.length" class="flex mt-3 p-2">
          <SearchInput class="w-full" v-model="filterHelper.q" />
        </div>
        <form @submit.prevent="apply">
          <div class="overflow-auto filter-container filters mt-3">
            <div v-if="filterHelper.filterableFields.length">
              <Accordion class="w-full" :multiple="true" :value="filterHelper.filterableFields.map((item: BlueprintField, index: number) => item.field)">
                <AccordionPanel :value="field.field" v-for="field of filterHelper.filterableFields" :key="field.field">
                  <AccordionHeader>{{ field.header }}</AccordionHeader>
                  <AccordionContent>
                    <slot :name="`filter-${field.field}`" :filter="filterHelper.filters[field.field]" />
                  </AccordionContent>
                </AccordionPanel>
              </Accordion>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="mt-2 flex w-full flex-col justify-center">
          <Button size="small" class="w-full" @click="apply" :label="$t('Apply')" />
          <Button class="mt-2 w-full" text severity="secondary" size="small" @click="cancel" :label="$t('Cancel')" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<style lang="scss">
.filters-panel.p-overlaypanel .p-overlaypanel-content {
  padding-top: .4rem;
}
.filters .p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-left: .4rem;
}
.filters .p-accordion .p-accordion-header .p-accordion-header-text {
  text-transform: uppercase;
  font-size: .9rem;
  font-weight: bold;
  color: var(--lar-text-color);
}
.filters .p-accordion .p-accordion-header .p-accordion-header-link {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}
.filters .p-accordion-content {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  padding-top: 0.3rem !important;
  padding-bottom: 0.8rem !important;
}
.filters .p-inputtext {
  padding: 0.6rem !important;
}
</style>

<script lang="ts">
// eslint-disable-next-line max-classes-per-file
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Accordion from 'primevue/accordion';
import AccordionContent from 'primevue/accordioncontent';
import AccordionHeader from 'primevue/accordionheader';
import AccordionPanel from 'primevue/accordionpanel';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';
import SearchInput from '@/components/form/search-input.vue';
import { DefaultEntityType } from '@/components/helpers/blueprint';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BlueprintField } from '../interfaces/blueprint-data';
import { FiltersHelper } from './filter-input-helper';

@Component({
  emits: ['updated', 'reset'], // use v-model
  components: {
    Button,
    FaIcon,
    SearchInput,
    Accordion,
    AccordionPanel,
    AccordionHeader,
    AccordionContent,
    Dialog,
  },
})
class FilterInput<TEntity extends DefaultEntityType = DefaultEntityType> extends ComponentBase {
  @Prop({ type: Object as PropType<FiltersHelper<TEntity>>, required: true })
  declare public filterHelper: FiltersHelper<TEntity>;

  public count = 0;

  public filterDialog = false;

  public created() {
    this.count = this.filterHelper.count;
  }

  public apply() {
    this.filterHelper.apply();
    this.$emit('updated', this.filterHelper.appliedFilters);
    this.filterDialog = false;
    this.count = this.filterHelper.count;
  }

  public reset() {
    this.filterHelper.reset();
    this.$emit('reset', this.filterHelper.appliedFilters);
    this.filterDialog = false;
    this.count = 0;
  }

  public cancel() {
    this.filterDialog = false;
  }
}

export default toNative(FilterInput);
</script>
