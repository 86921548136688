import { FilterObject, FilterOperator } from '@larva.io/blueapi-query';

import { BlueprintFilter, BlueprintFilterItem, FilterMatchMode } from '../blueprint/interfaces/blueprint-data';

export default class BARequestHelper {
  private static readonly filterOperatorMap = {
    [FilterMatchMode.EQUALS]: FilterOperator.equals,
    [FilterMatchMode.CONTAINS]: FilterOperator.contains,
    [FilterMatchMode.BETWEEN]: FilterOperator.between,
    [FilterMatchMode.GREATER_THAN]: FilterOperator.greater_than,
    [FilterMatchMode.GREATER_THAN_OR_EQUAL_TO]: FilterOperator.greater_than_or_equal,
    [FilterMatchMode.LESS_THAN]: FilterOperator.less_than,
    [FilterMatchMode.LESS_THAN_OR_EQUAL_TO]: FilterOperator.less_than_or_equal,
    [FilterMatchMode.IN]: FilterOperator.in,
    [FilterMatchMode.NOT_EQUALS]: FilterOperator.not_equals,
    [FilterMatchMode.STARTS_WITH]: FilterOperator.contains,
  };

  public static generateFilter(filter: BlueprintFilter): FilterObject {
    const filterObject: FilterObject = {};
    Object.entries(filter).forEach(([key, val]: [string, BlueprintFilterItem]) => {
      if (val.value !== undefined) { // filter not used
        switch (val.matchMode) {
          case FilterMatchMode.EQUALS: {
            filterObject[key] = val.value;
            break;
          }
          case FilterMatchMode.IN: {
            if (Array.isArray(val.value) && val.value.length > 0) {
              filterObject[key] = { [this.filterOperatorMap[val.matchMode]]: val.value };
            }
            break;
          }
          default: {
            filterObject[key] = { [this.filterOperatorMap[val.matchMode]]: val.value };
          }
        }
      }
    });
    return filterObject;
  }

  public static generateRoutePath(routePath: string, params: { [key: string]: string } | undefined) {
    const tokens = routePath.split('/');
    return tokens
      .map((token) => {
        // find param slots
        if (token.length > 0 && token[0] === ':') {
          if (!params) {
            throw new Error(`Param ${token.slice(1)} not supplied for api route ${routePath}`);
          }
          return params[token.slice(1)];
        }
        return token;
      })
      .join('/');
  }
}
