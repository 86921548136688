<!-- eslint-disable vue/max-len -->
<template>
  <div ref="pageGripMenu" class="page-grip-menu w-full">
    <div>
      <div class="select-none">
        <ul class="list-none flex flex-nowrap p-0 m-0 overflow-x-auto overscroll-auto">
          <li v-for="menuItem in menu" :key="menuItem.name" class="mb-1 mt-1 mr-1 lg:mr-2">
            <div v-if="!menuItem.href" class="border-r border-surface h-full" />
            <a v-else @click.prevent="changeRoute(menuItem.href)" @keypress.prevent="changeRoute(menuItem.href)" :class="{ 'bg-surface-100': isRouteActive(menuItem.href) }" class="whitespace-nowrap no-underline flex items-center cursor-pointer px-2 py-1.5 hover:bg-surface-100 rounded-lg transition-colors transition-duration-150 text-surface-800 overflow-hidden overflow-ellipsis">
              <span class="icon inline-block" v-if="menuItem.icon"><FaIcon :icon="menuItem.icon" /></span>
              <span class="font-medium text-sm md:text-base">{{menuItem.name}}</span>
              <Badge v-if="menuItem.badge" :value="menuItem.badge.value" :severity="menuItem.badge.severity" class="ml-2" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

  <style lang="scss" scoped>
  .icon {
    margin-right: 3px;
  }
  @media only screen and (max-width: 500px) {
    .icon {
      font-size: .85rem;
    }
  }
  </style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Badge from 'primevue/badge';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';

import { MenuItemRoute, SideMenuItem } from './page-side-menu.interfaces';

@Component({
  components: {
    FaIcon, Badge,
  },
})
class PageGripMenu extends ComponentBase {
  declare public $refs: { pageGripMenu: HTMLDivElement };

  @Prop(({ type: Object as PropType<SideMenuItem[]>, required: false, default: () => [] }))
  declare public menu: SideMenuItem[];

  public async mounted() {
    await this.$nextTick();
    const container = this.$refs.pageGripMenu.querySelector<HTMLDivElement>('.page-grip-menu .overflow-x-auto');
    const left = Math.max(0, (container?.querySelector<HTMLAnchorElement>('.bg-surface-100')?.offsetLeft ?? 0) - 60);
    if (left && container) {
      container.scrollLeft = left;
    }
  }

  public isRouteActive(href: MenuItemRoute): boolean {
    const activeRouteName = (this.$route.name ?? '') as string;
    return activeRouteName.startsWith(href.name);
  }

  public async changeRoute(href: MenuItemRoute) {
    await this.$router.push(href);
  }
}

export default toNative(PageGripMenu);
</script>
