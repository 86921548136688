import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, renderList as _renderList, createSlots as _createSlots, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "whitespace-nowrap" }
const _hoisted_2 = { class: "hidden sm:inline-block" }
const _hoisted_3 = { class: "align-end flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlueprintEditItem = _resolveComponent("BlueprintEditItem")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "!m-0 !p-1",
      text: "",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openDialog()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FaIcon, {
          class: "text-lg sm:text-base",
          icon: "plus"
        }),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label ?? _ctx.$t('New')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_Dialog, {
      onHide: _cache[5] || (_cache[5] = () => _ctx.$emit('hide')),
      visible: _ctx.newDialog,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newDialog) = $event)),
      header: _ctx.$t('Create new'),
      modal: true,
      position: "bottom"
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            disabled: _ctx.loading,
            class: "mr-2",
            label: _ctx.$t('Cancel'),
            icon: "pi pi-times",
            onClick: _cache[3] || (_cache[3] = _withModifiers(() => _ctx.newDialog = false, ["prevent"])),
            text: "",
            severity: "secondary"
          }, null, 8, ["disabled", "label"]),
          _createVNode(_component_Button, {
            loading: _ctx.loading,
            label: _ctx.$t('Create'),
            icon: "pi pi-check",
            severity: "success",
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.saveNew(_ctx.newItem)))
          }, null, 8, ["loading", "label"])
        ])
      ]),
      default: _withCtx(() => [
        (_ctx.newItem)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.saveNew(_ctx.newItem)), ["prevent"]))
            }, [
              _createVNode(_component_BlueprintEditItem, {
                mode: "new",
                invalidFields: _ctx.invalidFields,
                fields: _ctx.opts.fields,
                modelValue: _ctx.newItem,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem) = $event))
              }, _createSlots({ _: 2 }, [
                _renderList(_ctx.$slots, (index, name) => {
                  return {
                    name: name,
                    fn: _withCtx((data) => [
                      _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
                    ])
                  }
                })
              ]), 1032, ["invalidFields", "fields", "modelValue"])
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["visible", "header"])
  ]))
}