<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="item mr-1 whitespace-nowrap overflow-ellipsis overflow-hidden" v-if="item">
    <FaIcon v-if="faIcon" class="p-item-icon mr-1" :icon="faIcon" />
    <span class="whitespace-nowrap overflow-ellipsis overflow-hidden">
      <slot v-if="$slots.value" name="value" :item="item" />
      <template v-else>
        <template v-if="Array.isArray(labelField)">
          <template v-for="labelF of labelField" :key="labelF">
            {{item[labelF]}}&nbsp;
          </template>
        </template>
        <template v-else>
          {{item[labelField]}}
        </template>
      </template>
    </span>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';
import { BlueprintHelper, BlueprintOptions } from '@/components/helpers/blueprint';

@Component({
  components: { FaIcon },
})
class BlueprintItem extends ComponentBase {
  @Prop({ type: Object as PropType<BlueprintOptions>, required: true })
  declare public opts: BlueprintOptions;

  @Prop({ type: String, required: true })
  declare public id: string;

  @Prop
  declare public faIcon?: string;

  @Prop({ required: false, default: 'name' })
  declare public labelField: string | string[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public item: any = null;

  public loading = false;

  // eslint-disable-next-line class-methods-use-this
  public async created() {
    await this.getData();
  }

  public async getData() {
    try {
      this.loading = true;
      const res = await BlueprintHelper.getOne(this.opts, this.id, { skipNotFoundRedirect: true });
      this.item = res.data || null;
    } catch (err) {
      console.error(err); // @TODO
    } finally {
      this.loading = false;
    }
  }
}

export default toNative(BlueprintItem);
</script>
