import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "item mr-1 whitespace-nowrap overflow-ellipsis overflow-hidden" }
const _hoisted_2 = { class: "whitespace-nowrap overflow-ellipsis overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.faIcon)
      ? (_openBlock(), _createBlock(_component_FaIcon, {
          key: 0,
          class: "p-item-icon mr-1",
          icon: _ctx.faIcon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_2, [
      (_ctx.$slots.value)
        ? _renderSlot(_ctx.$slots, "value", {
            key: 0,
            label: _ctx.label
          })
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createTextVNode(_toDisplayString(_ctx.label), 1)
          ], 64))
    ])
  ]))
}