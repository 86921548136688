<template>
  <div class="data-view" v-if="initDone && !(hideWhenNoData && !fetchedData.length)">
    <div class="flex z-10 -mx-1 items-center justify-between" v-show="!!($slots.title || title || !noSearch || !noNew || $slots.new)">
      <div class="flex-grow flex">
        <template v-if="title || $slots.title">
          <slot v-if="$slots.title" name="title" />
          <div v-else class="overflow-hidden pl-2"><ContentTitle>{{ title }}</ContentTitle></div>
        </template>
      </div>
      <div class="mr-3 flex align-end">
        <div class="flex min-h-12 items-center">
          <FilterInput
            v-if="filterHelper && !noSearch"
            :filterHelper="filterHelper"
            @updated="initData"
            ref="filters"
            v-show="loading || fetchedData.length || filterHelper?.count"
            @reset="() => { initData(); $emit('resetFilters'); }"
          >
            <template v-for="col of filterHelper.filterableFields" :key="col.field" v-slot:[`filter-${String(col.field)}`]="data">
              <slot :name="`filter-${String(col.field)}`" v-bind="data" />
            </template>
          </FilterInput>
          <SortInput
            v-if="sortHelper && !!sortHelper.sortableFields.length && !noSort"
            v-show="loading || fetchedData.length || filterHelper?.count"
            @updated="initData"
            :sortHelper="sortHelper"
            class="ml-1"
          />
          <slot v-if="$slots.new" name="new" :length="fetchedData.length" />
          <NewInput
            class="ml-1"
            ref="new"
            :opts="opts"
            :newDefaultValues="newDefaultValues"
            :label="newLabel"
            v-show="!noNew && !$slots.new"
            @saved="initData"
          >
            <template v-for="(index, name) in $slots" v-slot:[name]="data">
              <slot :name="name" v-bind="data" />
            </template>
          </NewInput>
        </div>
      </div>
    </div>
    <div class="text-center mt-4" v-if="!loading && !fetchedData.length">
      <slot v-if="$slots.noResults && filterHelper?.count" name="noResults" />
      <div v-else-if="filterHelper?.count">
        <UndrawNoteList class="mb-3" primary-color='var(--lar-color-primary)' height="13rem" />
        <div class="mt-2">
          <ContentTitle>{{ $t('No records match your selected filters') }}</ContentTitle>
          <div>{{ $t('Adjust or expand your search criteria to find the information you need.') }}</div>
        </div>
        <Button :label="$t('Reset filters')" class="mt-2" size="small" text icon="pi pi-refresh" @click.prevent="resetFilters" />
      </div>
      <slot v-else-if="$slots.empty" name="empty" />
      <div v-else>
        <UndrawCompleted class="mb-3" primary-color='var(--lar-color-primary)' height="13rem" />
        <div class="mt-2">
          <ContentTitle>{{ $t('No entries have been created yet.') }}</ContentTitle>
          <template v-if="!noNew">
            <p>{{ $t('Start by adding new entries to enhance your experience and make the most out of our platform') }}</p>
            <Button class="mt-4" size="small" @click="openNew">
              <FaIcon class="mr-1" icon="plus" />
              {{ $t('Create new') }}
            </Button>
          </template>
        </div>
      </div>
    </div>
    <template v-for="(item, index) of fetchedData" :key="item.id">
      <slot v-if="$slots.list" name="list" :data="item" :length="fetchedData.length" :index="index" />
    </template>
    <!-- <div v-if="!noAutoLoad" ref="autoLoadMore" /> -->
    <slot v-if="$slots.more && (totalItemCount - fetchedData.length > 0)" name="more" :loadMore="loadMore" :dataLength="fetchedData.length ?? 0" :totalItemCount="totalItemCount" />
    <div v-else class="flex items-center justify-center mt-1">
      <Button :loading="loading" ref="more" iconPos="left" icon="pi pi-chevron-down" :label="`${$t('Show more')} (+${totalItemCount - fetchedData.length})`" class="m-0" size="small" text @click.prevent="loadMore" v-if="totalItemCount - fetchedData.length > 0" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
div.sticky {
  top: -1rem;
  background-color: var(--surface-ground);
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Button from 'primevue/button';
import UndrawCompleted from 'vue-undraw/UndrawCompleted';
import UndrawNoteList from 'vue-undraw/UndrawNoteList';

import {
  Component, ComponentBase, Prop, PropType, toNative, Watch,
} from '@/component-base';
import ContentTitle from '@/components/page/content-title.vue';
import PageActionBar from '@/components/page/page-action-bar.vue';

import {
  BlueprintHelper, BlueprintOptions, TableQuerySettings,
} from '../helpers/blueprint';
import FilterInput from './components/filter-input.vue';
import { FiltersHelper } from './components/filter-input-helper';
import NewInput from './components/new-input.vue';
import SortInput from './components/sort-input.vue';
import { SortHelper } from './components/sort-input-helper';
import { BlueprintFilter, DefaultEntityType } from './interfaces/blueprint-data';

@Component({
  emits: ['resetFilters', 'data:loaded'],
  components: {
    FaIcon,
    NewInput,
    FilterInput,
    Button,
    UndrawNoteList,
    UndrawCompleted,
    PageActionBar,
    ContentTitle,
    SortInput,
  },
})
class BlueprintDataView<TEntity extends DefaultEntityType = DefaultEntityType> extends ComponentBase {
  declare public $refs: { new: InstanceType<typeof NewInput>, autoLoadMore?: Element, filters?: InstanceType<typeof FilterInput> };

  @Prop({ type: String, required: true })
  declare public id: string;

  @Prop
  declare public title?: string;

  @Prop
  declare public newLabel?: string;

  @Prop({ type: Number, default: 8 })
  declare public limit: number;

  @Prop({ type: Object as PropType<BlueprintFilter>, required: true })
  declare public opts: BlueprintOptions<TEntity>;

  @Prop({ type: Object as PropType<Record<string, unknown>>, required: false })
  declare public newDefaultValues?: Record<string, unknown>;

  @Prop({ type: Object as PropType<BlueprintFilter>, required: false })
  declare public filter?: BlueprintFilter;

  @Prop({ type: String, required: false, default: 'updatedAt' })
  declare public sortBy: string;

  @Prop({ type: Boolean, required: false, default: true })
  declare public sortDesc?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  declare public noNew?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  declare public noSearch?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  declare public noSort?: boolean;

  // declare public noAutoLoad?: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  declare public hideWhenNoData?: boolean;

  @Watch('filter')
  public async filterWatcher() {
    await this.initData();
  }

  public tableQuerySettings = new TableQuerySettings();

  public loading = false;

  public fetchedData: TEntity[] = [];

  public totalItemCount = 0;

  public initDone = false;

  public filterHelper: FiltersHelper<TEntity> | null = null;

  public sortHelper: SortHelper<TEntity> | null = null;

  // private observer?: IntersectionObserver;

  public async created() {
    const opts = {
      opts: this.opts,
      id: this.id,
      $route: this.$route,
      $router: this.$router,
    };
    this.filterHelper = new FiltersHelper<TEntity>(opts);
    this.sortHelper = new SortHelper<TEntity>({
      ...opts,
      defaultSortBy: this.sortBy,
      defaultSortDesc: !!this.sortDesc,
    });
    await this.initData();
  }

  /*
  public async mounted() {
    await this.$nextTick();
    // register auto loader
    if (this.$refs.autoLoadMore) {
      this.observer = new IntersectionObserver((elements) => {
        if (this.loading || !elements.some((el) => el.isIntersecting)) {
          return;
        }
        this.loadMore();
      }, { root: document.querySelector('.pagecontent') ?? undefined, rootMargin: '50px' });
      this.observer.observe(this.$refs.autoLoadMore);
    }
  }

  unmounted() {
    this.observer?.disconnect();
  }
  */

  public async setFilters(input: { filters: BlueprintFilter, q: string }) {
    if (this.filterHelper) {
      this.filterHelper.filters = input.filters;
      this.filterHelper.q = input.q;
      await this.filterHelper.apply();
      await this.initData();
    } else {
      throw new Error('FilterHelper not initialized');
    }
  }

  public async resetFilters() {
    await this.$refs.filters?.reset();
  }

  public openNew() {
    this.$refs.new?.openDialog();
  }

  public async loadMore() {
    if (!this.totalItemCount || (this.totalItemCount - this.fetchedData.length <= 0) || this.loading) {
      return;
    }
    if (!this.tableQuerySettings.limit) { // type
      this.tableQuerySettings.limit = this.limit;
    }
    const tableQuerySettings = new TableQuerySettings();
    tableQuerySettings.limit = this.limit;
    tableQuerySettings.offset = this.tableQuerySettings.limit;
    tableQuerySettings.sortBy = this.sortHelper?.sortBy;
    tableQuerySettings.sortDesc = Boolean(this.sortHelper?.sortByDesc);
    tableQuerySettings.q = this.filterHelper?.appliedFilters.q ?? null;
    this.loading = true;
    try {
      const res = await this.getData({ ...this.filter, ...this.filterHelper?.appliedFilters.filters }, tableQuerySettings, { useGlobalLoading: false, cache: true });
      const fetchedData = res.data ?? [];
      this.totalItemCount = res.total;
      this.fetchedData.push(...fetchedData);
      // so if we to initData again, correct len is queried
      this.tableQuerySettings.limit += this.limit;
      await this.updateRoute();
    } catch (err) {
      this.error(err);
    } finally {
      this.loading = false;
      this.$emit('data:loaded');
    }
  }

  private async updateRoute() {
    const query = {
      [`${this.id}_limit`]: this.tableQuerySettings.limit,
      [`${this.id}_offset`]: this.tableQuerySettings.offset,
    };
    await this.$router.replace({
      ...this.$route,
      query: { ...this.$route.query, ...query },
    });
  }

  private getParamsFromRoute(): Partial<TableQuerySettings> {
    const limit = this.$route.query[`${this.id}_limit`];
    const offset = this.$route.query[`${this.id}_offset`];
    return {
      limit: limit ? Number(limit) : undefined,
      offset: offset ? Number(offset) : 0,
    };
  }

  public async initData(cache = true) {
    this.loading = true;
    const limit = this.limit === Infinity ? undefined : this.limit;
    const routeParams = this.getParamsFromRoute();
    this.tableQuerySettings.limit = routeParams.limit ?? limit;
    this.tableQuerySettings.sortBy = this.sortHelper?.sortBy;
    this.tableQuerySettings.sortDesc = Boolean(this.sortHelper?.sortByDesc);
    this.tableQuerySettings.q = this.filterHelper?.appliedFilters.q ?? null;
    try {
      const res = await this.getData({ ...this.filter, ...this.filterHelper?.appliedFilters.filters }, this.tableQuerySettings, { cache, useGlobalLoading: true });
      this.fetchedData = res.data ?? [];
      this.totalItemCount = res.total ?? 0;
    } catch (err) {
      this.totalItemCount = 0;
      this.fetchedData = [];
      this.error(err);
    } finally {
      this.initDone = true;
      this.loading = false;
      this.$emit('data:loaded');
    }
  }

  public async getData(filter: BlueprintFilter, querySettings: TableQuerySettings, { useGlobalLoading, cache }: { useGlobalLoading:boolean, cache:boolean }) {
    const { data } = await BlueprintHelper.getMany<TEntity>(this.opts, filter, querySettings, { skipGlobalLoading: !useGlobalLoading, cache: !!cache });
    return data;
  }
}

export default toNative(BlueprintDataView);
</script>
