<template>
  <div :class="{ 'content-loading': loading, animation: !loading }" class="pagecontent overflow-y-scroll overscroll-y-auto overflow-x-hidden bg-surface-ground border-surface px-2 py-3 md:px-3 flex flex-col flex-auto">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.content-loading {
  opacity: 0;
  pointer-events: none;
  // scale: 1.05;
  // transform: translateX(100%);
  filter: blur(1px);
}
.animation {
  //transform: translateX(0);
  transition: all .1s ease-in;
}
</style>

<script lang="ts">
import { Component, ComponentBase, toNative } from '@/component-base';

@Component({})
class PageContent extends ComponentBase {
  public get loading() {
    return this.RequestsState.globalHttpRequestsPending;
  }
}
export default toNative(PageContent);
</script>
