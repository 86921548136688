import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["grid", {
      'grid-flow-col': !_ctx.col, [`grid-cols-${_ctx.col}`]: _ctx.col, [`sm:grid-cols-${_ctx.sm}`]: _ctx.sm, [`md:grid-cols-${_ctx.md}`]: _ctx.md, [`lg:grid-cols-${_ctx.lg}`]: _ctx.lg, [`xl:grid-cols-${_ctx.xl}`]: _ctx.xl,
    }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}