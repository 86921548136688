import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!

  return (_openBlock(), _createBlock(_component_Select, {
    ref: "dropdown",
    modelValue: _ctx.internalVal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.internalVal) = $event)),
    options: _ctx.fetchedData,
    optionLabel: _ctx.labelField,
    optionValue: _ctx.optionValue,
    dataKey: _ctx.dataKey,
    filter: true,
    appendTo: "body",
    class: "w-full",
    onBeforeShow: _ctx.beforeShow,
    onFilter: _ctx.filterChanged,
    "filter-fields": _ctx.filterFields,
    loading: _ctx.loading,
    placeholder: _ctx.label,
    showClear: _ctx.showClear,
    disabled: _ctx.disabled
  }, _createSlots({
    empty: _withCtx(() => [
      (_ctx.$slots.empty)
        ? _renderSlot(_ctx.$slots, "empty", { key: 0 })
        : (_openBlock(), _createElementBlock("span", _hoisted_1, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('No items found')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('Loading....')), 1))
          ]))
    ]),
    emptyfilter: _withCtx(() => [
      (_ctx.$slots.emptyfilter)
        ? _renderSlot(_ctx.$slots, "emptyfilter", { key: 0 })
        : (_openBlock(), _createElementBlock("span", _hoisted_4, [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('No items to show')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('Loading....')), 1))
          ]))
    ]),
    _: 2
  }, [
    (_ctx.$slots.value)
      ? {
          name: "value",
          fn: _withCtx(({ placeholder, value }) => [
            (_ctx.$slots.value)
              ? _renderSlot(_ctx.$slots, "value", {
                  key: 0,
                  placeholder: placeholder,
                  value: value
                })
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.$slots.option)
      ? {
          name: "option",
          fn: _withCtx(({ option }) => [
            _renderSlot(_ctx.$slots, "option", { option: option })
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["modelValue", "options", "optionLabel", "optionValue", "dataKey", "onBeforeShow", "onFilter", "filter-fields", "loading", "placeholder", "showClear", "disabled"]))
}