<template>
  <Card class="data-card mb-3 px-3 py-2 border border-transparent" :class="{ 'hover:border-surface': clickable, 'cursor-pointer': clickable, 'sticky-header': stickyHeader }">
    <template #header>
      <div>
        <slot name="header" />
      </div>
    </template>
    <template #content>
      <div class="flex my-2 max-w-full overflow-hidden">
        <div class="mr-2 items-center flex !min-w-12" v-if="faIcon">
          <Avatar shape="circle" class="p-5">
            <FaIcon class="text-primary" size="xl" :icon="faIcon" />
          </Avatar>
        </div>
        <div v-else-if="$slots.icon">
          <slot name="icon" />
        </div>
        <div class="overflow-hidden flex-grow">
          <slot name="content" />
        </div>
        <div v-if="$slots.end">
          <slot name="end" />
        </div>
      </div>
    </template>
    <template #footer>
      <slot name="footer" />
    </template>
  </Card>
</template>

<style lang="scss" scoped>
.min-w-3-rem {
  min-width: 3rem;
}
</style>

<style>
.data-card.p-card.sticky-header .p-card-header {
  position: sticky;
  top: -1rem; /* py-2 */
  background: var(--lar-background-color);
}
</style>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Avatar from 'primevue/avatar';
import Card from 'primevue/card';

import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

@Component({
  components: {
    Card,
    FaIcon,
    Avatar,
  },
})
class DataCard extends ComponentBase {
  @Prop({ type: String, required: false })
  declare public faIcon?: string;

  @Prop({ type: Boolean, required: false, default: true })
  declare public clickable: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  declare public stickyHeader: boolean;
}

export default toNative(DataCard);
</script>
