<template>
  <div class="w-full">
    <div class="flex items-center mb-2">
      <RadioButton v-model="radioValue" :inputId="`${id}-no`" name="mode" :value="1" />
      <label :for="`${id}-no`" class="ml-2 uppercase text-sm">{{ $t('All') }}</label>
    </div>
    <div v-if="nullLabel" class="flex items-center mb-2">
      <RadioButton v-model="radioValue" :inputId="`${id}-un`" name="mode" :value="2" />
      <label :for="`${id}-un`" class="ml-2 uppercase text-sm">{{ nullLabel }}</label>
    </div>
    <div class="flex items-center mb-2">
      <RadioButton v-model="radioValue" :inputId="`${id}-us`" name="mode" :value="3" />
      <label :for="`${id}-us`" class="ml-2 flex-grow">
        <BlueprintDropdown
          v-if="radioValue === 3"
          class="w-full"
          :opts="opts"
          :filters="filters"
          :optionValue="optionValue"
          v-model="internalVal"
          :labelField="labelField"
          :showLimit="showLimit"
          :dataKey="dataKey"
          :label="label"
          :showClear="true"
        />
        <span class="uppercase text-sm" v-else>{{ applyFilterLabel ?? $t('Select Item') }}</span>
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import RadioButton from 'primevue/radiobutton';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';
import { BlueprintOptions } from '@/components/helpers/blueprint';

import BlueprintDropdown from '../blueprint/blueprint-dropdown.vue';
import { BlueprintFilter } from '../blueprint/interfaces/blueprint-data';

@Component({
  emits: ['update:modelValue'], // use v-model
  components: { BlueprintDropdown, RadioButton },
})
class FilterBlueprintDropdown extends ComponentBase {
  @Prop({ type: Object as PropType<BlueprintOptions>, required: true })
  declare public opts: BlueprintOptions;

  @Prop({ type: Object as PropType<BlueprintFilter>, required: false })
  declare public filters?: BlueprintFilter;

  @Prop({ type: [String, Function], required: false, default: 'name' })
  declare public labelField?: string | ((data: any) => string);

  @Prop({ type: String, required: false })
  declare public optionValue?: string;

  @Prop({ type: String, required: false })
  declare public nullLabel?: string;

  @Prop({ type: String, required: false })
  declare public applyFilterLabel?: string;

  @Prop({ type: Number, required: false })
  declare public showLimit?: number;

  @Prop({ type: [String, null, undefined], required: false })
  declare public label?: string;

  @Prop({ type: [String, null, undefined], required: false })
  declare public modelValue?: string | null;

  @Prop({ type: String, required: false, default: 'id' })
  declare public dataKey: string;

  // eslint-disable-next-line class-methods-use-this
  public get id() {
    return Math.floor(Math.random() * 100) + 1;
  }

  public get radioValue() {
    switch (this.internalVal) {
      case undefined:
        return 1;
      case null:
        return 2;
      default: return 3;
    }
  }

  public set radioValue(value: 1|2|3) {
    switch (value) {
      case 1:
        this.internalVal = undefined;
        break;
      case 2:
        this.internalVal = null;
        break;
      default:
        this.internalVal = '';
    }
  }

  public get internalVal() {
    return this.modelValue;
  }

  public set internalVal(val: string | null | undefined) {
    this.$emit('update:modelValue', val);
  }
}

export default toNative(FilterBlueprintDropdown);
</script>
