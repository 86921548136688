export default {
  deviceProxy: process.env.VUE_APP_BROKER_URL || 'wss://broker.test.fentrica.com',
  videoProxy: process.env.VUE_APP_VIDEO_URL || 'wss://video.broker.fentrica.com/',
  deviceCloudTimeout: 5000,
  //
  mantisURL: process.env.VUE_APP_MANTIS_API_URL || 'https://api.building.test.fentrica.com/mantis',
  hiveURL: process.env.VUE_APP_HIVE_API_URL || 'https://api.building.test.fentrica.com/hive',
  orgsURL: process.env.VUE_APP_ORGS_API_URL || 'https://api.building.test.fentrica.com/orgs',
  measurementsUrl: process.env.VUE_APP_MEASUREMENTS_API_URL || 'https://api.building.test.fentrica.com/measurements',
  beetleURL: process.env.VUE_APP_BEETLE_API_URL || 'https://api.building.test.fentrica.com/beetle',
};
