<template>
  <div><img :src="gravatarUrl" :alt="email" class="circle" /></div>
</template>

<style scoped>
img.circle {
  border-radius: 50%;
  width: auto;
  max-width:100%;
  height: auto;
  aspect-ratio: 1;
}
</style>

<script lang="ts">
import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

import md5 from '../helpers/md5';

@Component({})
class GravatarImg extends ComponentBase {
  @Prop({ type: String, required: false, default: 'X' })
  declare private firstname: string;

  @Prop({ type: String, required: false, default: 'X' })
  declare private lastname: string;

  @Prop({ type: String, required: false, default: 'default' })
  declare public email: string;

  @Prop({ type: Number, required: false, default: 200 })
  declare private size: number;

  @Prop({ type: String, required: false, default: 'identicon' })
  declare private defaultImage: string;

  @Prop({ type: String, required: false, default: 'e5e5e5' })
  declare private defaultBackground: string;

  private finalSize = 200;

  private get defaultUrl() {
    // https://ui-avatars.com/
    /*
    The order is as follows:
      name
      size
      background
      color
      length
      font-size
      rounded-md
      uppercase
      bold
      format
    */
    // eslint-disable-next-line no-nested-ternary
    return `https://ui-avatars.com/api/${this.firstname?.length > 0 ? this.firstname[0] : this.email ? this.email[0] : 'U'}+${
      this.lastname?.length ? this.lastname[0] : ''
    }/${this.finalSize}/${this.defaultBackground}/1a1a1a/2/0.45/false/true/true`;
  }

  public get gravatarUrl() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const hash = md5((this.email ?? '').trim().toLowerCase());
    return `https://secure.gravatar.com/avatar/${hash}?s=${this.finalSize}&d=${this.defaultUrl}`;
  }

  public created() {
    this.finalSize = this.size;
    if (this.finalSize < 24) {
      this.finalSize = 24;
    }

    if (this.finalSize > 2048) {
      this.finalSize = 2048;
    }
  }
}

export default toNative(GravatarImg);
</script>
