<template>
  <div class="max-h-screen min-h-screen max-w-screen min-w-screen">
    <!-- Toast, DynamicDialog and ConfirmDialog services use those DOM elements to append overlay -->
    <ConfirmDialog />
    <Toast position="top-center" />
    <DynamicDialog />
    <!-- authguard checks if user is authenticated and redirects to login page if not -->
    <AuthGuard ref="authGuard">
      <!-- PushNotifications guard, needs to be slotted to app, since we're listening global click event. Registers to PushNotifications if available -->
      <PushNotificationsGuard>
        <LarApp>
          <router-view />
        </LarApp>
      </PushNotificationsGuard>
    </AuthGuard>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/theme.scss';
@import '@/plugins/viewpoint-height-fix.scss';
</style>

<style lang="scss">
lar-modal, lar-video-modal {
  z-index: 1102 !important;
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
}
lar-app {
  overflow: visible !important;
}
</style>

<script lang="ts">
import '@/plugins/viewpoint-height-fix';

import { LarApp } from '@larva.io/webcomponents-vue';
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import ConfirmDialog from 'primevue/confirmdialog';
import DynamicDialog from 'primevue/dynamicdialog';
import Toast from 'primevue/toast';

import { Component, ComponentBase, toNative } from '@/component-base';
import AuthGuard from '@/modules/auth/components/auth-guard.vue';
import PushNotificationsGuard from '@/modules/auth/components/push-notifications-guard.vue';

import { BuildingHttpClient } from './http';

@Component({
  components: {
    AuthGuard,
    LarApp,
    ConfirmDialog,
    Toast,
    DynamicDialog,
    PushNotificationsGuard,
  },
})
class App extends ComponentBase {
  declare $refs: { authGuard: InstanceType<typeof AuthGuard> };

  private requests = 0;

  private globalLoaderDisabled = false;

  private globalLoaderTimer?: number;

  private requestInceptors: { http: BuildingHttpClient, id: number }[] = [];

  private responseInceptors: { http: BuildingHttpClient, id: number }[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private addAxiosPendingRequest(req: InternalAxiosRequestConfig) {
    if (this.globalLoaderDisabled) {
      return req;
    }
    if (!req.skipGlobalLoading) {
      this.requests += 1;
      this.globalLoading();
    }
    return req;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, class-methods-use-this
  private subAxiosPendingRequest(res: AxiosResponse) {
    if (this.globalLoaderDisabled) {
      return res;
    }
    if (!res.config?.skipGlobalLoading) {
      this.requests = this.requests === 0 ? 0 : this.requests - 1;
      this.globalLoading();
    }
    return res;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private subAxiosPendingRequestError(err?: Error & { config?: InternalAxiosRequestConfig}) {
    if (this.globalLoaderDisabled) {
      return Promise.reject(err);
    }
    if (!err?.config?.skipGlobalLoading) {
      this.requests = this.requests === 0 ? 0 : this.requests - 1;
      this.globalLoading();
    }
    return Promise.reject(err);
  }

  private afterEachRoute() {
    this.requests = this.requests === 0 ? 0 : this.requests - 1;
    this.globalLoading();
  }

  private beforeEachRoute() {
    this.requests += 1;
    this.globalLoaderDisabled = false;
    this.globalLoading();
  }

  private globalLoading(interval = 100) {
    if (this.globalLoaderTimer) {
      clearTimeout(this.globalLoaderTimer);
      this.globalLoaderTimer = undefined;
    }
    if (this.requests === 0) {
      this.globalLoaderTimer = setTimeout(() => {
        this.RequestsState.globalLoading(false);
        this.requests = 0;
        this.globalLoaderDisabled = true;
      }, interval) as unknown as number;
    } else if (!this.RequestsState.globalHttpRequestsPending) {
      this.RequestsState.globalLoading(true);
    }
  }

  public async reloadAuthGuard() {
    await this.$nextTick();
    await this.$refs.authGuard.reload();
  }

  public async created() {
    this.reloadAuthGuard = this.reloadAuthGuard.bind(this);
    this.eventBus.on('login', this.reloadAuthGuard);
    this.eventBus.on('logout-done', this.reloadAuthGuard);
    this.eventBus.on('beforeEachRoute', this.beforeEachRoute);
    this.eventBus.on('afterEachRoute', this.afterEachRoute);
    this.requestInceptors = [this.mantis, this.hive, this.orgs, this.measurements, this.beetle].map((h) => ({ http: h, id: h.interceptors.request.use(this.addAxiosPendingRequest, this.subAxiosPendingRequestError) }));
    this.responseInceptors = [this.mantis, this.hive, this.orgs, this.measurements, this.beetle].map((h) => ({ http: h, id: h.interceptors.response.use(this.subAxiosPendingRequest, this.subAxiosPendingRequestError) }));
  }

  public async beforeUnmount() {
    this.eventBus.off('login', this.reloadAuthGuard);
    this.eventBus.on('logout-done', this.reloadAuthGuard);
    this.requestInceptors.map((i) => i.http.interceptors.request.eject(i.id));
    this.responseInceptors.map((i) => i.http.interceptors.response.eject(i.id));
  }
}

export default toNative(App);
</script>
