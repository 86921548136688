import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faIcon = _resolveComponent("faIcon")!

  return (_openBlock(), _createElementBlock("a", {
    onKeypress: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.toggleMenu()), ["prevent"])),
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleMenu()), ["prevent"])),
    "aria-label": _ctx.$t('Toggle Menu'),
    class: "toggle-menu my-1 px-2 py-2 items-center text-surface-600 hover:text-surface-900 hover:bg-surface-100 font-medium rounded-md cursor-pointer transition-duration-150 transition-colors"
  }, [
    _createVNode(_component_faIcon, { icon: "bars" })
  ], 40, _hoisted_1))
}