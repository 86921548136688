import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "pt-1 w-full" }
const _hoisted_2 = ["onClick", "onKeyup"]
const _hoisted_3 = { class: "inline-block" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chips, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([{ 'text-primary': _ctx.optionChecked(option.value), 'border-surface': !_ctx.optionChecked(option.value), 'border-primary': _ctx.optionChecked(option.value) }, "border rounded-2xl w-auto inline-block py-1 px-2 mr-1 mb-1 cursor-pointer uppercase text-sm"]),
        onClick: ($event: any) => (_ctx.toggleOption(option.value)),
        onKeyup: ($event: any) => (_ctx.toggleOption(option.value)),
        key: index
      }, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'px-2': !_ctx.optionChecked(option.value) })
        }, [
          (_ctx.optionChecked(option.value))
            ? (_openBlock(), _createBlock(_component_FaIcon, {
                key: 0,
                icon: "check",
                fixedWidth: "",
                class: "text-primary"
              }))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(option.name ?? option.text ?? option.value ?? _ctx.$t('Not Defined')), 1)
        ], 2)
      ], 42, _hoisted_2))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.allowCustom)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_InputText, {
              class: "mt-1 w-40",
              modelValue: _ctx.custom,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.custom) = $event)),
              placeholder: _ctx.$t('Add Custom'),
              onKeydown: _ctx.customInputKeyPress
            }, null, 8, ["modelValue", "placeholder", "onKeydown"]),
            _createVNode(_component_Button, {
              onClick: _ctx.addCustom,
              disabled: !_ctx.custom,
              class: "flex-none m-1",
              "rounded-md": "",
              icon: "pi pi-plus",
              "area-label": _ctx.$t('Send'),
              text: ""
            }, null, 8, ["onClick", "disabled", "area-label"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}