import { FilterMatchMode } from '@primevue/core/api';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';

import { BlueprintOptions, DefaultEntityType } from '@/components/helpers/blueprint';

import { BlueprintFilter } from '../interfaces/blueprint-data';

export { FilterMatchMode };
export interface FilterHelperOpts<TEntity extends DefaultEntityType = DefaultEntityType> {
  id: string,
  $route: RouteLocationNormalizedLoaded,
  opts: BlueprintOptions<TEntity>,
  $router: Router
}

export class FiltersHelper<TEntity extends DefaultEntityType = DefaultEntityType> {
  private internalFilters: BlueprintFilter = {};

  private internalQ = '';

  private internalCount = 0;

  private id: string;

  private opts: BlueprintOptions<TEntity>;

  private $router: Router;

  private $route: RouteLocationNormalizedLoaded;

  private appliedFiltersInternal: { q: string, filters: BlueprintFilter } = { q: '', filters: {} };

  constructor(options: FilterHelperOpts<TEntity>) {
    this.id = options.id;
    this.$route = options.$route;
    this.$router = options.$router;
    this.opts = options.opts;
    this.apply = this.apply.bind(this);
    this.resetToDefault = this.resetToDefault.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.reset = this.reset.bind(this);
    this.resetToDefault();
    this.setFromQuery();
    this.applyFilters();
  }

  public get appliedFilters() {
    return this.appliedFiltersInternal;
  }

  public get count() {
    return this.internalCount;
  }

  public get filterableFields() {
    return (this.opts.fields?.filter((f) => !!f.filterable) ?? []);
  }

  public get searchableFields() {
    return (this.opts.fields?.filter((f) => !!f.searchable) ?? []);
  }

  public async reset() {
    this.resetToDefault();
    await this.apply();
  }

  public set q(value: string) {
    this.internalQ = value;
  }

  public get q() {
    return this.internalQ;
  }

  public set filters(filters: BlueprintFilter) {
    this.internalFilters = filters;
  }

  public get filters() {
    return this.internalFilters;
  }

  public async apply() {
    this.applyFilters();
    await this.updateRoute();
  }

  private applyFilters() {
    const filters: BlueprintFilter = {};
    let count = 0;
    Object.keys(this.filters).forEach((k) => {
      if ((!Array.isArray(this.filters[k].value) && this.filters[k].value !== '' && this.filters[k].value !== undefined) || (Array.isArray(this.filters[k].value) && this.filters[k].value.length)) {
        count += 1;
        filters[k] = this.filters[k];
      }
    });
    this.internalCount = count + (this.q ? 1 : 0);
    this.appliedFiltersInternal = { q: this.q, filters };
  }

  private setFromQuery() {
    const params = this.$route.query;
    this.filterableFields.forEach((k) => {
      const field = String(k.field);
      const queryField = params[`${this.id}_${field}`];
      const matchMode = k.filterMatchMode;
      if (queryField !== undefined) {
        this.internalFilters[field].value = (matchMode === FilterMatchMode.IN || matchMode === FilterMatchMode.BETWEEN) && !Array.isArray(queryField) ? [queryField] : queryField;
      }
    });
  }

  private resetToDefault() {
    this.internalFilters = {};
    this.q = '';
    // generate default filters
    this.filterableFields.forEach((k) => {
      const field = String(k.field);
      this.internalFilters[field] = {
        value: k.filterDefaultValue ?? undefined,
        matchMode: k.filterMatchMode ?? FilterMatchMode.EQUALS,
      };
    });
  }

  private async updateRoute() {
    const query: Record<string, string|undefined> = {};
    query[`${this.id}_q`] = this.appliedFilters.q || undefined;
    // clear all already set filters
    this.filterableFields.forEach((k) => {
      query[`${this.id}_${String(k.field)}`] = undefined;
    });
    // set filters to query params.
    Object.keys(this.appliedFilters.filters).forEach((k) => {
      query[`${this.id}_${k}`] = this.appliedFilters.filters[k].value;
    });
    await this.$router.replace({
      ...this.$route,
      query: { ...this.$route.query, ...query },
    });
  }
}
