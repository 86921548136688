<template>
  <div class="whitespace-nowrap">
    <Button class="!m-0 !p-1" text @click="openDialog()">
      <FaIcon class="text-lg sm:text-base" icon="plus" />
      <span class="hidden sm:inline-block">{{ label ?? $t('New') }}</span>
    </Button>
    <Dialog @hide="() => $emit('hide')" v-model:visible="newDialog" :header="$t('Create new')" :modal="true" position="bottom">
      <form v-if="newItem" @submit.prevent="saveNew(newItem)">
        <BlueprintEditItem mode="new" :invalidFields="invalidFields" :fields="opts.fields" v-model="newItem">
          <template v-for="(index, name) in $slots" v-slot:[name]="data">
            <slot :name="name" v-bind="data" />
          </template>
        </BlueprintEditItem>
      </form>
      <template #footer>
        <div class="align-end flex">
          <Button :disabled="loading" class="mr-2" :label="$t('Cancel')" icon="pi pi-times" @click.prevent="() => newDialog = false" text severity="secondary" />
          <Button :loading="loading" :label="$t('Create')" icon="pi pi-check" severity="success" @click="saveNew(newItem)" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import deepClone from 'lodash/cloneDeep';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';

import {
  BlueprintHelper, BlueprintOptions, DefaultEntityType,
} from '../../helpers/blueprint';
import BlueprintEditItem from './blueprint-edit-item.vue';

@Component({
  emits: ['saved', 'hide'],
  components: {
    Button,
    FaIcon,
    Dialog,
    BlueprintEditItem,
  },
})
class NewInput<TEntity extends DefaultEntityType = DefaultEntityType> extends ComponentBase {
  @Prop({ type: Object as PropType<BlueprintOptions<TEntity>> })
  declare public opts: BlueprintOptions<TEntity>;

  @Prop({ type: Object as PropType<Record<string, unknown>> })
  declare public newDefaultValues?: Record<string, unknown>;

  @Prop
  declare public label?: string;

  public filterVisible = false;

  public loading = false;

  public newDialog = false;

  public newItem: Record<string, unknown> = {};

  public invalidFields: string[] = [];

  public openDialog() {
    this.newItem = deepClone(this.newDefaultValues ?? {});
    this.invalidFields = [];
    this.newDialog = true;
  }

  public async saveNew(item: Record<string, unknown>) {
    try {
      this.invalidFields = [];
      this.loading = true;
      await BlueprintHelper.createOne<TEntity>(this.opts, item);
      this.newDialog = false;
      this.$emit('saved');
    } catch (err: any) {
      if (err.response?.status === 400 && err.response?.data?.message && Array.isArray(err.response?.data?.message)) {
        this.parseBlueprintError(err.response.data.message);
      } else {
        this.error(err);
      }
    } finally {
      this.loading = false;
    }
  }

  private parseBlueprintError(err: string[]) {
    this.invalidFields = [];
    const message = err.map((sentence) => {
      const words = sentence.split(' ');
      const fieldName = words[0];
      const field = (this.opts.fields || []).find((f) => f.field === fieldName);
      if (field) {
        words[0] = String(field.label || field.header || field.field);
        this.invalidFields.push(String(field.field));
      }
      return words.join(' ');
    }).join('. ');
    this.error(message);
  }
}

export default toNative(NewInput);
</script>
