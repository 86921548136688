import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  ref: "pageGripMenu",
  class: "page-grip-menu w-full"
}
const _hoisted_2 = { class: "select-none" }
const _hoisted_3 = { class: "list-none flex flex-nowrap p-0 m-0 overflow-x-auto overscroll-auto" }
const _hoisted_4 = {
  key: 0,
  class: "border-r border-surface h-full"
}
const _hoisted_5 = ["onClick", "onKeypress"]
const _hoisted_6 = {
  key: 0,
  class: "icon inline-block"
}
const _hoisted_7 = { class: "font-medium text-sm md:text-base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Badge = _resolveComponent("Badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (menuItem) => {
            return (_openBlock(), _createElementBlock("li", {
              key: menuItem.name,
              class: "mb-1 mt-1 mr-1 lg:mr-2"
            }, [
              (!menuItem.href)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4))
                : (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    onClick: _withModifiers(($event: any) => (_ctx.changeRoute(menuItem.href)), ["prevent"]),
                    onKeypress: _withModifiers(($event: any) => (_ctx.changeRoute(menuItem.href)), ["prevent"]),
                    class: _normalizeClass([{ 'bg-surface-100': _ctx.isRouteActive(menuItem.href) }, "whitespace-nowrap no-underline flex items-center cursor-pointer px-2 py-1.5 hover:bg-surface-100 rounded-lg transition-colors transition-duration-150 text-surface-800 overflow-hidden overflow-ellipsis"])
                  }, [
                    (menuItem.icon)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                          _createVNode(_component_FaIcon, {
                            icon: menuItem.icon
                          }, null, 8, ["icon"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(menuItem.name), 1),
                    (menuItem.badge)
                      ? (_openBlock(), _createBlock(_component_Badge, {
                          key: 1,
                          value: menuItem.badge.value,
                          severity: menuItem.badge.severity,
                          class: "ml-2"
                        }, null, 8, ["value", "severity"]))
                      : _createCommentVNode("", true)
                  ], 42, _hoisted_5))
            ]))
          }), 128))
        ])
      ])
    ])
  ], 512))
}