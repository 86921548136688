/* eslint-disable max-classes-per-file */
import { BAQuery } from '@larva.io/blueapi-query';
import axios, { AxiosRequestConfig } from 'axios';
import deepClone from 'lodash/cloneDeep';

import { BuildingHttpClient } from '@/http';
import http from '@/plugins/http';

import {
  BlueprintField, BlueprintFilter, BlueprintFilterMatchMode, CallbackFunc,
  DefaultEntityType,
} from '../blueprint/interfaces/blueprint-data';
import BARequestHelper from './blueapi';

export type { DefaultEntityType } from '../blueprint/interfaces/blueprint-data';
export class BlueprintOptions<TEntity extends DefaultEntityType = DefaultEntityType> {
  public http: BuildingHttpClient = axios.create();

  public apiRoutePath = '';

  public params: { [key: string]: string } = {};

  public queryParams?: { [key: string]: any } = {};

  public fields?: BlueprintField<TEntity>[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // itemsGetter?: (data: any) => { id: string }[];

  public beforeNew?: (values: TEntity) => Promise<void> | void;

  public beforeEdit?: (values: TEntity) => Promise<void> | void;

  public editItem?: (values: TEntity) => Promise<void> | void;

  public beforeSave?: (values: TEntity) => Promise<boolean | void> | (boolean | void);

  public afterSave?: (values: TEntity, isNew?: boolean) => Promise<void> | void;

  public newItem?: (values: TEntity) => Promise<void> | void;

  public deleteItem?: (values: TEntity) => Promise<void> | void;

  public beforeDelete?: (values: TEntity) => Promise<boolean | void> | (boolean | void);

  public afterDelete?: (values: TEntity) => Promise<void> | void;

  // onFilter can be used to format filter value before sending it to API endpoint filter formatter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onFilter?: (filter: BlueprintFilter, params: Record<string, any>) => Promise<BlueprintFilter> | BlueprintFilter;
}

export class TableQuerySettings {
  public sortBy?: string;

  public sortDesc = false;

  public offset = 0;

  public limit: number | undefined = 10;

  public q: string | null = null;
}

export class BlueprintHelper {
  static filterEditItem<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>, item: T) {
    if (opts.fields) {
      const fields = opts.fields.filter((f) => !f.noEdit);
      const newItem = fields.reduce((acc: any, cur) => {
        acc[cur.field] = item[cur.field];
        return acc;
      }, {});
      return newItem;
    }
    return {};
  }

  static filterNewItem<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>, item: T) {
    if (opts.fields) {
      const fields = opts.fields.filter((f) => !f.noNew);
      const newItem = fields.reduce((acc: any, cur) => {
        acc[cur.field] = item[cur.field];
        return acc;
      }, {});
      return newItem;
    }
    return {};
  }

  static async doCallback<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>, callbackFunc: CallbackFunc, currentItem: any, param1?: any) {
    // eslint-disable-next-line prefer-const
    if (!!opts[callbackFunc] && typeof opts[callbackFunc] === 'function') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return (opts[callbackFunc] as any)?.(currentItem, param1);
    }
    return false;
  }

  static async createRel<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>): Promise<void> {
    const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}`;
    await opts.http.put(url);
  }

  static async removeRel<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>): Promise<void> {
    const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}`;
    await opts.http.delete(url);
  }

  // eslint-disable-next-line default-param-last
  static async getCount<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>, filters: BlueprintFilter = {}, tableQuerySettings?: { q?: string}): Promise<number> {
    let params: Record<string, any> = {};
    const fieldFilters = opts.onFilter ? await BlueprintHelper.doCallback(opts, 'onFilter', deepClone(filters), params) : deepClone(filters);
    const filter = BARequestHelper.generateFilter(fieldFilters);
    params = BAQuery.createQueryObject({
      ...params,
      filter,
      q: tableQuerySettings?.q ?? undefined,
    });
    const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}/count`;
    const { data } = await opts.http.get(url, { params: { ...params, ...opts.queryParams }, signal: http.signal });
    return data.count;
  }

  static async getMany<TEntity extends DefaultEntityType = DefaultEntityType, TResponse = { data: TEntity[], count: number, total: number}>(opts: BlueprintOptions<TEntity>, filters: BlueprintFilter, tableQuerySettings?: Partial<TableQuerySettings>, axiosOptions?: AxiosRequestConfig) {
    let params: Record<string, any> = {};
    const fieldFilters = opts.onFilter ? await BlueprintHelper.doCallback(opts, 'onFilter', deepClone(filters), params) : deepClone(filters);
    const filter = BARequestHelper.generateFilter(fieldFilters);
    params = BAQuery.createQueryObject({
      ...params,
      filter,
      limit: tableQuerySettings?.limit || 10,
      offset: tableQuerySettings?.offset || 0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      order: tableQuerySettings?.sortBy ? [[tableQuerySettings?.sortBy, tableQuerySettings?.sortDesc ? 'DESC' : 'ASC']] : undefined,
      q: tableQuerySettings?.q ?? undefined,
    });
    const url = BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params);
    return opts.http.get<TResponse>(url, { ...axiosOptions, params: { ...params, ...opts.queryParams }, signal: http.signal });
  }

  static async getOne<T extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<T>, id?: string, axiosOptions?: AxiosRequestConfig) {
    let params: Record<string, any> = {};
    params = {};
    const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}${id ? `/${id}` : ''}`;
    return opts.http.get<T>(url, { ...axiosOptions, params: { ...params, ...opts.queryParams }, signal: http.signal });
  }

  static async deleteOne<TEntity extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<TEntity>, item: any, axiosOptions?: AxiosRequestConfig) {
    if (!item.id) {
      throw new Error('Invalid item to remove');
    }
    const shouldCancel = await BlueprintHelper.doCallback(opts, 'beforeDelete', item);
    if (shouldCancel) {
      // cancel the save
      return;
    }
    let res: any;
    if (opts.deleteItem) {
      res = await this.doCallback(opts, 'deleteItem', item);
    } else {
      const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}/${item.id}`;
      const { data } = await opts.http.delete(url, { ...axiosOptions, params: { ...opts.queryParams }, signal: http.signal });
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      res = data;
    }
    await this.doCallback(opts, 'afterDelete', item);
  }

  static async updateOne<TEntity extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<TEntity>, item: any, axiosOptions?: AxiosRequestConfig) {
    const shouldCancel = await BlueprintHelper.doCallback(opts, 'beforeSave', item);
    if (shouldCancel) {
      // cancel the save
      return;
    }
    let res: any;
    if (opts.editItem) {
      res = await this.doCallback(opts, 'editItem', item);
    } else {
      const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}${item.id ? `/${item.id}` : ''}`;
      const { data } = await opts.http.patch(url, BlueprintHelper.filterEditItem(opts, item), { ...axiosOptions, params: { ...opts.queryParams }, signal: http.signal });
      res = data;
    }
    await this.doCallback(opts, 'afterSave', res);
  }

  static async createOne<TEntity extends DefaultEntityType = DefaultEntityType>(opts: BlueprintOptions<TEntity>, item: any, axiosOptions?: AxiosRequestConfig) {
    const shouldCancel = await BlueprintHelper.doCallback(opts, 'beforeSave', item, true);
    if (shouldCancel) {
      // cancel the save
      return null;
    }
    let res: any;
    if (opts.newItem) {
      res = await this.doCallback(opts, 'newItem', item);
      await this.doCallback(opts, 'afterSave', res, true);
    } else {
      const url = `${BARequestHelper.generateRoutePath(opts.apiRoutePath, opts.params)}`;
      res = await opts.http.post<TEntity>(url, BlueprintHelper.filterNewItem(opts, item), { ...axiosOptions, params: { ...opts.queryParams }, signal: http.signal });
      await this.doCallback(opts, 'afterSave', res.data, true);
    }
    return res;
  }

  /**
   * Fixes case where HTTP headers are too large for Azure
  */
  static async getManyInFilterChunks<T extends DefaultEntityType>(opts: BlueprintOptions<T>, filterField: string, filterValues: string[], limit = 20) {
    const promises = [];
    for (let i = 0; i < filterValues.length; i += limit) {
      const filters: BlueprintFilter = {
        [filterField]: {
          value: filterValues.slice(i, i + limit),
          matchMode: BlueprintFilterMatchMode.IN,
        },
      };
      // eslint-disable-next-line no-async-promise-executor
      const promise = new Promise<T[]>(async (resolve) => {
        const { data: chunkItems } = await BlueprintHelper.getMany<T>(opts, filters, { limit });
        resolve(chunkItems.data ?? []);
      });
      promises.push(promise);
    }
    const ret = await Promise.all(promises);
    return ret.flat();
  }
}
