<template>
  <IconField class="w-full">
    <InputIcon class="pi pi-search" />
    <InputText class="w-full" v-model="qInternal" @input="emitValue" :placeholder="placeholder ?? $t('Search')" />
  </IconField>
</template>

<script lang="ts">
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import InputText from 'primevue/inputtext';

import {
  Component, ComponentBase, Prop, toNative, Watch,
} from '@/component-base';

@Component({
  components: {
    InputText,
    IconField,
    InputIcon,
  },
  emits: ['update:modelValue', 'updated'],
})
class SearchInput extends ComponentBase {
  @Prop({ type: String, required: false })
  declare public modelValue: string;

  @Prop({ type: String, required: false })
  declare public placeholder: string;

  @Watch('modelValue')
  public modelValueWatcher(newValue: string | null) {
    this.qInternal = newValue ?? '';
  }

  private searchTimeout: number | undefined = undefined;

  public qInternal = '';

  public mounted() {
    this.qInternal = this.modelValue ?? '';
  }

  public emitValue() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = setTimeout(() => {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
      this.$emit('update:modelValue', this.qInternal);
      this.$emit('updated');
    }, 300) as unknown as number;
  }
}

export default toNative(SearchInput);
</script>
