<template>
  <div>
    <template v-for="field of (mode === 'new' ? newFields : editFields)" :key="field.field">
      <slot v-if="$slots[`edit-${field.field}`]" :name="`edit-${field.field}`" :model="modelValue" :field="field" />
      <BlueprintEditItemField :invalid="invalidFields?.includes(field.field)" v-else v-model="modelValue[field.field]" @update:modelValue="$emit('update:modelValue', modelValue)" :field="field" />
    </template>
  </div>
</template>

<script lang="ts">
import {
  Component, ComponentBase, Prop, PropType, toNative,
} from '@/component-base';

import { BlueprintField } from '../interfaces/blueprint-data';
import BlueprintEditItemField from './blueprint-item-field.vue';

@Component({
  emits: ['update:modelValue'],
  components: { BlueprintEditItemField },
})
class BlueprintEditItem extends ComponentBase {
  @Prop({ type: Array as PropType<BlueprintField[]>, required: true })
  declare public fields: BlueprintField[];

  @Prop({ type: String as PropType<'new' | 'edit'>, required: true })
  declare public mode: 'new' | 'edit';

  @Prop({ type: Array as PropType<string[]>, required: false })
  declare public invalidFields?: string[];

  @Prop({ type: Object as PropType<Record<string, any>>, required: true })
  declare public modelValue: { [key: string]: any };

  public get editFields() {
    return this.fields.filter((f) => !f.noEdit).filter((f) => !f.hidden);
  }

  public get newFields() {
    return this.fields.filter((f) => !f.noNew).filter((f) => !f.hidden);
  }
}

export default toNative(BlueprintEditItem);
</script>
