import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "whitespace-nowrap"
}
const _hoisted_2 = { class: "flex flex-col flex-auto" }
const _hoisted_3 = { class: "flex sticky top-0 py-4 z-10 bg-surface-0 justify-end mt-1" }
const _hoisted_4 = {
  key: 0,
  class: "flex mt-3 p-2"
}
const _hoisted_5 = { class: "overflow-auto filter-container filters mt-3" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mt-2 flex w-full flex-col justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_AccordionHeader = _resolveComponent("AccordionHeader")!
  const _component_AccordionContent = _resolveComponent("AccordionContent")!
  const _component_AccordionPanel = _resolveComponent("AccordionPanel")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.filterHelper.filterableFields.length || _ctx.filterHelper.searchableFields.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Button, {
          class: _normalizeClass(["!m-0 !p-1", { 'font-bold': !!_ctx.count }]),
          text: "",
          onClick: _cache[0] || (_cache[0] = ($event) => _ctx.filterDialog = true)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_FaIcon, {
              class: "text-lg sm:text-base",
              icon: "sliders"
            }),
            (_openBlock(), _createElementBlock("span", {
              class: "hidden sm:inline-block",
              key: _ctx.count
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.$t('Filters')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.count ? `(${_ctx.count})` : ''), 1)
            ]))
          ]),
          _: 1
        }, 8, ["class"]),
        _createVNode(_component_Dialog, {
          visible: _ctx.filterDialog,
          "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filterDialog) = $event)),
          closeOnEscape: false,
          showHeader: false,
          modal: true
        }, {
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Button, {
                size: "small",
                class: "w-full",
                onClick: _ctx.apply,
                label: _ctx.$t('Apply')
              }, null, 8, ["onClick", "label"]),
              _createVNode(_component_Button, {
                class: "mt-2 w-full",
                text: "",
                severity: "secondary",
                size: "small",
                onClick: _ctx.cancel,
                label: _ctx.$t('Cancel')
              }, null, 8, ["onClick", "label"])
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Button, {
                  size: "small",
                  disabled: !_ctx.filterHelper.count,
                  onClick: _ctx.reset,
                  text: "",
                  icon: "pi pi-refresh",
                  label: _ctx.$t('Reset all')
                }, null, 8, ["disabled", "onClick", "label"])
              ]),
              (_ctx.filterHelper.searchableFields.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_SearchInput, {
                      class: "w-full",
                      modelValue: _ctx.filterHelper.q,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterHelper.q) = $event))
                    }, null, 8, ["modelValue"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("form", {
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.apply && _ctx.apply(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.filterHelper.filterableFields.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_Accordion, {
                          class: "w-full",
                          multiple: true,
                          value: _ctx.filterHelper.filterableFields.map((item, index) => item.field)
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterHelper.filterableFields, (field) => {
                              return (_openBlock(), _createBlock(_component_AccordionPanel, {
                                value: field.field,
                                key: field.field
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_AccordionHeader, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(field.header), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  _createVNode(_component_AccordionContent, null, {
                                    default: _withCtx(() => [
                                      _renderSlot(_ctx.$slots, `filter-${field.field}`, {
                                        filter: _ctx.filterHelper.filters[field.field]
                                      })
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 3
                        }, 8, ["value"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 32)
            ])
          ]),
          _: 3
        }, 8, ["visible"])
      ]))
    : _createCommentVNode("", true)
}