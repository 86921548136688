<template>
  <div
    class="summary-card-col"
    :class="{
      'col-auto': !col, [`col-span-${col}`]: col, [`sm:col-span-${sm}`]: sm, [`md:col-span-${md}`]: md, [`lg:col-span-${lg}`]: lg, [`xl:col-span-${xl}`]: xl,
    }">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.summary-card-col {
  padding: 0.8rem;
}
</style>

<script lang="ts">

import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

@Component({})
class SummaryCardCol extends ComponentBase {
  @Prop({ type: [String, Number], required: false, default: '12' })
  declare public col?: string;

  @Prop({ type: [String, Number], required: false })
  declare public sm?: string;

  @Prop({ type: [String, Number], required: false })
  declare public md?: string;

  @Prop({ type: [String, Number], required: false })
  declare public lg?: string;

  @Prop({ type: [String, Number], required: false })
  declare public xl?: string;
}

export default toNative(SummaryCardCol);
</script>
