<template>
  <span v-tooltip.top="formatted" :class="classProp">
    {{ textual }}
  </span>
</template>

<script lang="ts">
import moment, { Duration, Moment } from 'moment';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';

import DateHelper from '../helpers/date';

@Component({})
class FormatterDateTimeAgo extends ComponentBase {
  @Prop({ type: [String, Date, Object as PropType<Moment>], required: false })
  declare value: Date | Moment;

  @Prop({ type: Object as PropType<Duration>, required: false, default: () => moment.duration({ hours: 12 }) })
  declare textualTimeDuration: Duration;

  @Prop({ type: String, required: false, default: DateHelper.defaultDateTimeFormatMoment })
  declare format: string;

  @Prop({ type: String, required: false, default: undefined })
  declare class: string;

  get classProp() { return this.class; }

  get textual() {
    if (moment(this.value) > moment().add(this.textualTimeDuration) || moment(this.value) < moment().subtract(this.textualTimeDuration)) {
      return this.formatted;
    }
    return moment(this.value).fromNow();
  }

  get formatted() {
    return moment(this.value).format(this.format);
  }
}

export default toNative(FormatterDateTimeAgo);
</script>
