export const en = {
  larva: {
    business: {
      // login
      loginError: 'Login Error',
      login: 'Login',
      verify: 'Verify',
      back: 'Back',
      forgetPassword: 'Forgot password',
      continue: 'Continue',
      change: 'Change',
      haveCode: 'I already have a code',
      sendCode: 'Send code',
      twoStepVerification: 'Please enter code you received via sms or generated by mobile application',
      passwordChangeRequired: 'Password change required',
      currentPassword: 'Current Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      password: 'Password',
      username: 'E-mail',
      verification: 'Your account needs to be verified',
      code: 'Code',
      enterCore: 'Please enter code and new password. Code sent to:',
      // passcode
      enter_passcode: 'Please enter passcode',
      security: 'Security',
    },
  },
};
const ee = { };
const ru = { };

export default {
  en, ee, ru,
};
