<template>
  <div class="pt-1 w-full">
    <div
      :class="{ 'text-primary': optionChecked(option.value), 'border-surface': !optionChecked(option.value), 'border-primary': optionChecked(option.value) }"
      @click="toggleOption(option.value)"
      @keyup="toggleOption(option.value)"
      class="border rounded-2xl w-auto inline-block py-1 px-2 mr-1 mb-1 cursor-pointer uppercase text-sm"
      v-for="(option, index) of chips"
      :key="index"
    >
      <div :class="{ 'px-2': !optionChecked(option.value) }">
        <FaIcon icon="check" fixedWidth class="text-primary" v-if="optionChecked(option.value)" />
        {{ option.name ?? option.text ?? option.value ?? $t('Not Defined') }}
      </div>
    </div>
    <div class="inline-block">
      <div v-if="allowCustom" class="flex items-center">
        <InputText class="mt-1 w-40" v-model="custom" :placeholder="$t('Add Custom')" @keydown="customInputKeyPress" />
        <Button @click="addCustom" :disabled="!custom" class="flex-none m-1" rounded-md icon="pi pi-plus" :area-label="$t('Send')" text />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import InputText from 'primevue/inputtext';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
  Watch,
} from '@/component-base';

@Component({
  emits: ['update:modelValue'], // use v-model
  components: {
    Chip, FaIcon, Button, InputText,
  },
})
class FilterChips extends ComponentBase {
  @Prop({ type: Array as PropType<(string|null)[]>, required: false })
  declare public modelValue?: (string|null)[] | null;

  @Prop({ type: Array as PropType<{ name?: string, text?: string, value: string|null}[]>, required: true })
  declare public options: { name?: string, text?: string, value: string|null}[];

  @Prop({ type: Boolean, required: false, default: false })
  declare public allowCustom: boolean;

  @Watch('modelValue')
  public modelValueWatch() {
    this.internalVal = this.modelValue ?? [];
  }

  public custom = '';

  public internalValue: (string|null)[] | null = [];

  private internalChips: { name: string, text?: string, value: string|null}[] = [];

  public created() {
    this.internalVal = this.modelValue ?? [];
    this.internalChips = (this.internalVal ?? [])
      .filter((ext): ext is string => !!ext && !this.chips.find((f) => f.value === ext))
      .map((ext) => ({ name: ext, value: ext }));
  }

  public get internalVal() {
    return this.internalValue;
  }

  public set internalVal(val: (string | null)[] | null) {
    this.internalValue = val;
    this.$emit('update:modelValue', this.internalValue);
  }

  public addCustom() {
    const ext = this.custom;
    if (ext) {
      const values = [
        ...(this.internalVal ?? []),
        ext,
      ];
      this.internalVal = values;
      if (!this.chips.find((f) => f.value === ext)) {
        this.internalChips.push({ name: ext, value: ext });
      }
    }
    this.custom = '';
  }

  public async customInputKeyPress(e: KeyboardEvent) {
    if (e.key === 'Enter' || e.key === 'Tab') {
      this.addCustom();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  public get chips() {
    return [
      ...this.options,
      ...this.internalChips,
    ];
  }

  public optionChecked(option: string|null) {
    return this.internalVal?.some((f) => f === option);
  }

  public toggleOption(option: string|null) {
    const index = this.internalVal?.indexOf(option);
    if (index !== undefined && index !== -1) {
      this.internalVal?.splice(index, 1);
    } else {
      this.internalVal?.push(option);
    }
    this.$emit('update:modelValue', this.internalValue);
  }
}

export default toNative(FilterChips);
</script>
