import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, vShow as _vShow, renderList as _renderList, createSlots as _createSlots, withDirectives as _withDirectives, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "data-view"
}
const _hoisted_2 = { class: "flex z-10 -mx-1 items-center justify-between" }
const _hoisted_3 = { class: "flex-grow flex" }
const _hoisted_4 = {
  key: 1,
  class: "overflow-hidden pl-2"
}
const _hoisted_5 = { class: "mr-3 flex align-end" }
const _hoisted_6 = { class: "flex min-h-12 items-center" }
const _hoisted_7 = {
  key: 0,
  class: "text-center mt-4"
}
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "mt-2" }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "mt-2" }
const _hoisted_12 = {
  key: 2,
  class: "flex items-center justify-center mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ContentTitle = _resolveComponent("ContentTitle")!
  const _component_FilterInput = _resolveComponent("FilterInput")!
  const _component_SortInput = _resolveComponent("SortInput")!
  const _component_NewInput = _resolveComponent("NewInput")!
  const _component_UndrawNoteList = _resolveComponent("UndrawNoteList")!
  const _component_Button = _resolveComponent("Button")!
  const _component_UndrawCompleted = _resolveComponent("UndrawCompleted")!
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_ctx.initDone && !(_ctx.hideWhenNoData && !_ctx.fetchedData.length))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.title || _ctx.$slots.title)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_ctx.$slots.title)
                    ? _renderSlot(_ctx.$slots, "title", { key: 0 }, undefined, true)
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_ContentTitle, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                          ]),
                          _: 1
                        })
                      ]))
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.filterHelper && !_ctx.noSearch)
                ? _withDirectives((_openBlock(), _createBlock(_component_FilterInput, {
                    key: 0,
                    filterHelper: _ctx.filterHelper,
                    onUpdated: _ctx.initData,
                    ref: "filters",
                    onReset: _cache[0] || (_cache[0] = () => { _ctx.initData(); _ctx.$emit('resetFilters'); })
                  }, _createSlots({ _: 2 }, [
                    _renderList(_ctx.filterHelper.filterableFields, (col) => {
                      return {
                        name: `filter-${String(col.field)}`,
                        fn: _withCtx((data) => [
                          _renderSlot(_ctx.$slots, `filter-${String(col.field)}`, _normalizeProps(_guardReactiveProps(data)), undefined, true)
                        ])
                      }
                    })
                  ]), 1032, ["filterHelper", "onUpdated"])), [
                    [_vShow, _ctx.loading || _ctx.fetchedData.length || _ctx.filterHelper?.count]
                  ])
                : _createCommentVNode("", true),
              (_ctx.sortHelper && !!_ctx.sortHelper.sortableFields.length && !_ctx.noSort)
                ? _withDirectives((_openBlock(), _createBlock(_component_SortInput, {
                    key: 1,
                    onUpdated: _ctx.initData,
                    sortHelper: _ctx.sortHelper,
                    class: "ml-1"
                  }, null, 8, ["onUpdated", "sortHelper"])), [
                    [_vShow, _ctx.loading || _ctx.fetchedData.length || _ctx.filterHelper?.count]
                  ])
                : _createCommentVNode("", true),
              (_ctx.$slots.new)
                ? _renderSlot(_ctx.$slots, "new", {
                    key: 2,
                    length: _ctx.fetchedData.length
                  }, undefined, true)
                : _createCommentVNode("", true),
              _withDirectives(_createVNode(_component_NewInput, {
                class: "ml-1",
                ref: "new",
                opts: _ctx.opts,
                newDefaultValues: _ctx.newDefaultValues,
                label: _ctx.newLabel,
                onSaved: _ctx.initData
              }, _createSlots({ _: 2 }, [
                _renderList(_ctx.$slots, (index, name) => {
                  return {
                    name: name,
                    fn: _withCtx((data) => [
                      _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)), undefined, true)
                    ])
                  }
                })
              ]), 1032, ["opts", "newDefaultValues", "label", "onSaved"]), [
                [_vShow, !_ctx.noNew && !_ctx.$slots.new]
              ])
            ])
          ])
        ], 512), [
          [_vShow, !!(_ctx.$slots.title || _ctx.title || !_ctx.noSearch || !_ctx.noNew || _ctx.$slots.new)]
        ]),
        (!_ctx.loading && !_ctx.fetchedData.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              (_ctx.$slots.noResults && _ctx.filterHelper?.count)
                ? _renderSlot(_ctx.$slots, "noResults", { key: 0 }, undefined, true)
                : (_ctx.filterHelper?.count)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_UndrawNoteList, {
                        class: "mb-3",
                        "primary-color": "var(--lar-color-primary)",
                        height: "13rem"
                      }),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_ContentTitle, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('No records match your selected filters')), 1)
                          ]),
                          _: 1
                        }),
                        _createElementVNode("div", null, _toDisplayString(_ctx.$t('Adjust or expand your search criteria to find the information you need.')), 1)
                      ]),
                      _createVNode(_component_Button, {
                        label: _ctx.$t('Reset filters'),
                        class: "mt-2",
                        size: "small",
                        text: "",
                        icon: "pi pi-refresh",
                        onClick: _withModifiers(_ctx.resetFilters, ["prevent"])
                      }, null, 8, ["label", "onClick"])
                    ]))
                  : (_ctx.$slots.empty)
                    ? _renderSlot(_ctx.$slots, "empty", { key: 2 }, undefined, true)
                    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_UndrawCompleted, {
                          class: "mb-3",
                          "primary-color": "var(--lar-color-primary)",
                          height: "13rem"
                        }),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_ContentTitle, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('No entries have been created yet.')), 1)
                            ]),
                            _: 1
                          }),
                          (!_ctx.noNew)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("p", null, _toDisplayString(_ctx.$t('Start by adding new entries to enhance your experience and make the most out of our platform')), 1),
                                _createVNode(_component_Button, {
                                  class: "mt-4",
                                  size: "small",
                                  onClick: _ctx.openNew
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_FaIcon, {
                                      class: "mr-1",
                                      icon: "plus"
                                    }),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('Create new')), 1)
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ], 64))
                            : _createCommentVNode("", true)
                        ])
                      ]))
            ]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fetchedData, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: item.id
          }, [
            (_ctx.$slots.list)
              ? _renderSlot(_ctx.$slots, "list", {
                  key: 0,
                  data: item,
                  length: _ctx.fetchedData.length,
                  index: index
                }, undefined, true)
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        (_ctx.$slots.more && (_ctx.totalItemCount - _ctx.fetchedData.length > 0))
          ? _renderSlot(_ctx.$slots, "more", {
              key: 1,
              loadMore: _ctx.loadMore,
              dataLength: _ctx.fetchedData.length ?? 0,
              totalItemCount: _ctx.totalItemCount
            }, undefined, true)
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              (_ctx.totalItemCount - _ctx.fetchedData.length > 0)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    loading: _ctx.loading,
                    ref: "more",
                    iconPos: "left",
                    icon: "pi pi-chevron-down",
                    label: `${_ctx.$t('Show more')} (+${_ctx.totalItemCount - _ctx.fetchedData.length})`,
                    class: "m-0",
                    size: "small",
                    text: "",
                    onClick: _withModifiers(_ctx.loadMore, ["prevent"])
                  }, null, 8, ["loading", "label", "onClick"]))
                : _createCommentVNode("", true)
            ]))
      ]))
    : _createCommentVNode("", true)
}