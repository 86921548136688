<template>
  <PageToolbar>
    <template #start>
      <div class="start flex items-center mx-1" v-if="!loading">
        <PageSideMenuToggle v-if="!$slots.start && sideMenuAvailable" class="md:hidden" />
        <slot name="start" v-if="$slots.start" />
      </div>
    </template>
    <div v-if="!loading ">
      <div class="content overflow-hidden overflow-ellipsis flex items-center flex-grow">
        <slot />
      </div>
    </div>
    <template #end>
      <div class="end items-center" v-if="!loading">
        <slot name="end" v-if="$slots.end" />
      </div>
    </template>
  </PageToolbar>
</template>

<script lang="ts">
import { Component, ComponentBase, toNative } from '@/component-base';

import PageSideMenuToggle from './page-side-menu-toggle.vue';
import PageToolbar from './page-toolbar.vue';

@Component({
  components: {
    PageToolbar,
    PageSideMenuToggle,
  },
})
class PageActionBar extends ComponentBase {
  public sideMenuAvailable = false;

  mounted() {
    this.sideMenuAvailable = !!document.querySelectorAll('.page-side-menu').length;
  }

  public get loading() {
    return this.RequestsState.globalHttpRequestsPending;
  }
}
export default toNative(PageActionBar);
</script>
