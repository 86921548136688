import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex my-2 max-w-full overflow-hidden" }
const _hoisted_2 = {
  key: 0,
  class: "mr-2 items-center flex !min-w-12"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "overflow-hidden flex-grow" }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    class: _normalizeClass(["data-card mb-3 px-3 py-2 border border-transparent", { 'hover:border-surface': _ctx.clickable, 'cursor-pointer': _ctx.clickable, 'sticky-header': _ctx.stickyHeader }])
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "header", {}, undefined, true)
      ])
    ]),
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.faIcon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_Avatar, {
                shape: "circle",
                class: "p-5"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FaIcon, {
                    class: "text-primary",
                    size: "xl",
                    icon: _ctx.faIcon
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]))
          : (_ctx.$slots.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
              ]))
            : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ]),
        (_ctx.$slots.end)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "end", {}, undefined, true)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ]),
    _: 3
  }, 8, ["class"]))
}