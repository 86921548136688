<template>
  <div
    class="grid"
    :class="{
      'grid-flow-col': !col, [`grid-cols-${col}`]: col, [`sm:grid-cols-${sm}`]: sm, [`md:grid-cols-${md}`]: md, [`lg:grid-cols-${lg}`]: lg, [`xl:grid-cols-${xl}`]: xl,
    }">
    <slot />
  </div>
</template>

<script lang="ts">

import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

@Component({})
class Grid extends ComponentBase {
  @Prop({ type: [String, Number], required: false })
  declare public col?: string;

  @Prop({ type: [String, Number], required: false })
  declare public sm?: string;

  @Prop({ type: [String, Number], required: false })
  declare public md?: string;

  @Prop({ type: [String, Number], required: false })
  declare public lg?: string;

  @Prop({ type: [String, Number], required: false })
  declare public xl?: string;
}

export default toNative(Grid);
</script>
