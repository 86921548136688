<template>
  <div
    :class="{
      'col-auto': !col, [`col-span-${col}`]: col, [`sm:col-span-${sm}`]: sm, [`md:col-span-${md}`]: md, [`lg:col-span-${lg}`]: lg, [`xl:col-span-${xl}`]: xl,
    }">
    <slot />
  </div>
</template>

<script lang="ts">

import {
  Component, ComponentBase, Prop, toNative,
} from '@/component-base';

@Component({})
class Col extends ComponentBase {
  @Prop({ type: [String, Number], required: false })
  declare public col?: string;

  @Prop({ type: [String, Number], required: false })
  declare public sm?: string;

  @Prop({ type: [String, Number], required: false })
  declare public md?: string;

  @Prop({ type: [String, Number], required: false })
  declare public lg?: string;

  @Prop({ type: [String, Number], required: false })
  declare public xl?: string;
}

export default toNative(Col);
</script>
