<template>
  <div class="whitespace-nowrap">
    <Button class="!m-0 !p-1" text @click="($event: MouseEvent) => sortDialog = true">
      <FaIcon class="text-lg sm:text-base" icon="arrow-down-wide-short" />
      <span class="hidden sm:inline-block">{{ $t('Sort') }}</span>
    </Button>
    <Dialog v-model:visible="sortDialog" :closeOnEscape="false" :showHeader="false" :modal="true">
      <div class="flex flex-col flex-auto pt-2">
        <div class="flex justify-end mt-1" />
        <form @submit.prevent="apply">
          <h3 class="uppercase my-2 text-sm font-semibold">{{ $t('Sort by') }}</h3>
          <div class="w-full mt-3">
            <div class="flex items-center mr-3 mb-3" v-for="field of sortHelper.sortableFields" :key="field.field">
              <RadioButton v-model="sortHelper.sortBy" :inputId="`${sortHelper.id}-${field.field}`" name="sort" :value="field.field" />
              <label :for="`${sortHelper.id}-${field.field}`" class="ml-1 uppercase text-sm">{{ field.header ?? field.label }}</label>
            </div>
          </div>
          <h3 class="uppercase mb-2 text-sm font-semibold">{{ $t('Order') }}</h3>
          <div class="w-full mt-3">
            <div class="flex items-center mr-3 mb-3">
              <RadioButton v-model="sortHelper.sortByDesc" :inputId="`${sortHelper.id}-o-desc`" name="desc" :value="true" />
              <label :for="`${sortHelper.id}-o-desc`" class="ml-1 uppercase text-sm">
                <FaIcon class="mx-1" icon="arrow-down-wide-short" />
                {{ $t('Descending') }}
              </label>
            </div>
            <div class="flex items-center mr-3 mb-3">
              <RadioButton v-model="sortHelper.sortByDesc" :inputId="`${sortHelper.id}-o-asc`" name="asc" :value="false" />
              <label :for="`${sortHelper.id}-o-asc`" class="ml-1 uppercase text-sm">
                <FaIcon class="mx-1" icon="arrow-down-short-wide" />
                {{ $t('Ascending') }}
              </label>
            </div>
          </div>
        </form>
      </div>
      <template #footer>
        <div class="mt-2 flex w-full flex-col justify-center">
          <Button size="small" class="w-full" @click="apply" :label="$t('Apply')" />
          <Button class="mt-2 w-full" text severity="secondary" size="small" @click="cancel" :label="$t('Cancel')" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
} from '@/component-base';

import { SortHelper } from './sort-input-helper';

@Component({
  emits: ['updated', 'reset'], // use v-model
  components: {
    Button,
    FaIcon,
    RadioButton,
    Dialog,
  },
})
class SortInput extends ComponentBase {
  @Prop({ type: Object as PropType<SortHelper> })
  declare public sortHelper: SortHelper;

  public sortDialog = false;

  public apply() {
    this.sortHelper.apply();
    this.sortDialog = false;
    this.$emit('updated');
  }

  public reset() {
    this.sortHelper.reset();
    this.sortDialog = false;
    this.$emit('updated');
  }

  public cancel() {
    this.sortHelper.cancel();
    this.sortDialog = false;
  }
}

export default toNative(SortInput);
</script>
