<!-- eslint-disable max-len -->
<template>
  <a
    @keypress.prevent="toggleMenu()"
    @click.prevent="toggleMenu()"
    :aria-label="$t('Toggle Menu')"
    class="toggle-menu my-1 px-2 py-2 items-center text-surface-600 hover:text-surface-900 hover:bg-surface-100 font-medium rounded-md cursor-pointer transition-duration-150 transition-colors"
  >
    <faIcon icon="bars" />
  </a>
</template>
<script lang="ts">
import { FontAwesomeIcon as FaIcon } from '@fortawesome/vue-fontawesome';

import { Component, ComponentBase, toNative } from '@/component-base';

export function toggleMenu() {
  // we use here query selector
  document.querySelectorAll('.page-side-menu').forEach((menuitem) => {
    if (menuitem.classList.contains('hidden') && menuitem.classList.contains('md:block')) {
      menuitem.classList.remove('md:block');
      menuitem.classList.remove('hidden');
      menuitem.classList.add('md:hidden');
      menuitem.classList.add('block');
    } else {
      menuitem.classList.remove('md:hidden');
      menuitem.classList.remove('block');
      menuitem.classList.add('md:block');
      menuitem.classList.add('hidden');
    }
  });
}

@Component({
  components: {
    FaIcon,
  },
})
class PageSideMenuToggle extends ComponentBase {
  // eslint-disable-next-line class-methods-use-this
  public toggleMenu() {
    toggleMenu();
  }
}

export default toNative(PageSideMenuToggle);
</script>
