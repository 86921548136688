<template>
  <Card class="summary-card">
    <template #content>
      <slot />
    </template>
  </Card>
</template>

<style lang="scss">
  .summary-card.p-card .p-card-content {
    padding: 0;
  }
  .summary-card-grid {
    border-bottom: 1px solid var(--lar-background-color-step-100);
  }
  .summary-card-grid:last-child {
    border-bottom: 0 !important;
  }
  .summary-card-col {
    border-right: 1px solid var(--lar-background-color-step-100);
  }
  .summary-card-col:last-child {
    border-right: 0 !important;
  }
</style>

<script lang="ts">
import Card from 'primevue/card';

import { Component, ComponentBase, toNative } from '@/component-base';

@Component({
  components: {
    Card,
  },
})
class SummaryCard extends ComponentBase {
}

export default toNative(SummaryCard);
</script>
