export default {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY || 'AIzaSyBrRKjKrz0OJpVOP4ALTMHE_b_Uga7AydM',
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || 'larvaio-building.firebaseapp.com',
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || 'larvaio-building',
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || 'larvaio-building.appspot.com',
  messagingSenderId: process.env.VUE_APP_FIREBASE_SENDER_ID || '30731424257',
  appId: process.env.VUE_APP_FIREBASE_APP_ID || '1:30731424257:web:535dc8688dfd50b1daa600',
  //
  vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY || 'BBmxC78jNq2BRPy_hOlAvHW5K-AuZUiU-OtBy1ynZfKbOnvkiZAUV1-akWsWgYcvNko58M6bnk9StnaOX3spRDc',
};
