import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "item mr-1 whitespace-nowrap overflow-ellipsis overflow-hidden"
}
const _hoisted_2 = { class: "whitespace-nowrap overflow-ellipsis overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FaIcon = _resolveComponent("FaIcon")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.faIcon)
          ? (_openBlock(), _createBlock(_component_FaIcon, {
              key: 0,
              class: "p-item-icon mr-1",
              icon: _ctx.faIcon
            }, null, 8, ["icon"]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_2, [
          (_ctx.$slots.value)
            ? _renderSlot(_ctx.$slots, "value", {
                key: 0,
                item: _ctx.item
              })
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (Array.isArray(_ctx.labelField))
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.labelField, (labelF) => {
                      return (_openBlock(), _createElementBlock(_Fragment, { key: labelF }, [
                        _createTextVNode(_toDisplayString(_ctx.item[labelF]) + "  ", 1)
                      ], 64))
                    }), 128))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createTextVNode(_toDisplayString(_ctx.item[_ctx.labelField]), 1)
                    ], 64))
              ], 64))
        ])
      ]))
    : _createCommentVNode("", true)
}