<template>
  <Select
    ref="dropdown"
    v-model="internalVal"
    :options="fetchedData"
    :optionLabel="labelField"
    :optionValue="optionValue"
    :dataKey="dataKey"
    :filter="true"
    appendTo="body"
    class="w-full"
    @beforeShow="beforeShow"
    @filter="filterChanged"
    :filter-fields="filterFields"
    :loading="loading"
    :placeholder="label"
    :showClear="showClear"
    :disabled="disabled"
  >
    <template v-if="$slots.value" #value="{ placeholder, value }">
      <slot v-if="$slots.value" name="value" :placeholder="placeholder" :value="value" />
    </template>
    <template v-if="$slots.option" #option="{ option }">
      <slot name="option" :option="option" />
    </template>
    <template #empty>
      <slot v-if="$slots.empty" name="empty" />
      <span v-else>
        <span v-if="!loading">{{$t('No items found')}}</span>
        <span v-else>{{$t('Loading....')}}</span>
      </span>
    </template>
    <template #emptyfilter>
      <slot v-if="$slots.emptyfilter" name="emptyfilter" />
      <span v-else>
        <span v-if="!loading">{{$t('No items to show')}}</span>
        <span v-else>{{$t('Loading....')}}</span>
      </span>
    </template>
  </Select>
</template>

<script lang="ts">
import Select, { SelectFilterEvent } from 'primevue/select';

import {
  Component, ComponentBase, Prop, PropType,
  toNative,
  Watch,
} from '@/component-base';
import { BlueprintHelper, BlueprintOptions, TableQuerySettings } from '@/components/helpers/blueprint';

import { BlueprintFilter } from './interfaces/blueprint-data';

@Component({
  emits: ['update:modelValue'], // use v-model
  components: { Select },
})
class BlueprintDropdown extends ComponentBase {
  declare public $refs: { dropdown: InstanceType<typeof Select> };

  @Prop({ type: Object as PropType<BlueprintOptions>, required: true })
  declare public opts: BlueprintOptions;

  @Prop({ type: Object as PropType<BlueprintFilter>, required: false })
  declare public filters?: BlueprintFilter;

  @Prop({ type: [String, Function], required: false, default: 'name' })
  declare public labelField: string | ((data: any) => string);

  @Prop
  declare public optionValue: string;

  @Prop({ type: Number, required: false, default: 50 })
  declare public showLimit: number;

  @Prop
  declare public label: string | undefined;

  @Prop({ type: [String, null], default: null })
  declare public modelValue: string | null;

  @Prop({ type: Boolean, required: false, default: false })
  declare public showClear: boolean;

  @Prop({ type: String, default: 'id' })
  declare public dataKey: string;

  @Prop({ type: Boolean, required: false, default: false })
  declare public noFetchSearch: boolean;

  @Prop({ type: Boolean, required: false, default: false })
  declare public disabled: boolean;

  @Watch('modelValue')
  public async modelValueWatcher() {
    await this.getItem();
  }

  private item: Record<string, any> | null = null;

  public get internalVal() {
    return this.item;
  }

  public set internalVal(val: Record<string, any> | null) {
    this.item = val;
    if (this.item == null || (this.modelValue !== this.item[this.dataKey])) {
      this.$emit('update:modelValue', this.item ? this.item[this.dataKey] : null);
    }
  }

  public get labelVal() {
    if (this.internalVal && typeof this.labelField === 'function') {
      return this.labelField(this.internalVal);
    }
    if (this.internalVal && typeof this.labelField === 'string') {
      return this.internalVal[this.labelField];
    }
    return this.label;
  }

  private tableQuerySettings = new TableQuerySettings();

  private searchTimeout: ReturnType<typeof setTimeout> | undefined = undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public fetchedData: any[] = [];

  public loading = false;

  // eslint-disable-next-line class-methods-use-this
  public async beforeShow() {
    //
  }

  public async created() {
    this.tableQuerySettings.limit = this.showLimit;
    await Promise.all([this.getItem(), this.getData()]);
  }

  public get filterFields() {
    return this.opts.fields?.filter((f) => f.searchable).map((f) => f.field) ?? [];
  }

  // eslint-disable-next-line class-methods-use-this
  public filterChanged(event: SelectFilterEvent) {
    if (this.noFetchSearch) {
      return;
    }
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.tableQuerySettings.q = event.value;
    this.searchTimeout = setTimeout(async () => {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = undefined;
      await this.getData();
    }, 100);
  }

  private async getItem() {
    try {
      this.loading = true;
      if (this.modelValue) { // fetch selected item based input id
        const { data: item } = await BlueprintHelper.getOne(this.opts, this.modelValue);
        if (this.fetchedData.length === 0) {
          this.fetchedData = [item];
        }
        this.internalVal = item;
      }
    } catch (err) {
      this.error(err);
    } finally {
      this.loading = false;
    }
  }

  private async getData() {
    try {
      this.loading = true;
      // fetch available items
      const { data } = await BlueprintHelper.getMany(this.opts, this.filters ?? {}, this.tableQuerySettings, { skipGlobalLoading: true });
      const items = data?.data || [];
      // if current selected value is not on a list, append it
      if (this.internalVal && !items.find((i) => this.internalVal && i[this.dataKey] === this.internalVal[this.dataKey])) {
        items.push(this.internalVal);
      }
      this.fetchedData = items;
    } catch (err) {
      this.error(err);
    } finally {
      this.loading = false;
    }
  }
}

export default toNative(BlueprintDropdown);
</script>
